// theme.js (you can call it whatever you want)


export const ButtonType = {
    primary: "bg-azure-radiance-500  hover:bg-azure-radiance-600  text-white cursor-pointer rounded-lg flex justify-center h-fit my-2",
    secondary: "bg-white text-black cursor-pointer rounded-lg flex justify-center h-fit my-2",
    basic: "bg-transparent text-white cursor-pointer rounded-lg flex justify-center h-fit border border-white my-2",
    simple: "bg-transparent text-white cursor-pointer flex justify-center h-fit my-2",
    basicPrimary: "bg-transparent text-blue-500 cursor-pointer rounded-lg flex justify-center h-fit border border-blue-500 my-2",
    dark: "bg-black text-white cursor-pointer rounded-lg flex justify-center h-fit my-2",
    submit: "bg-green-500 h cursor-pointer hover:bg-green-700 text-white  rounded-lg flex justify-center h-fit my-2",
    disabled: "bg-gray-400 cursor-not-allowed rounded-lg flex justify-center h-fit my-2 text-white border border-gray-400 ",
    purple: "bg-pirple-500 hover:bg-pirple-200 text-white cursor-pointer rounded-lg flex justify-center h-fit my-2",


};

export const ButtonSize = {
  small: "py-2 px-3 text-xs ",
  medium: "py-2 px-4 text-base",
  large: "py-2 px-6 lg:text-lg text-base",
  extraLarge: "py-4 px-6 text-lg" 
};

export const ButtonWeight = {
    normal: "font-normal",
    medium: "font-medium",
    semibold: "font-semibold",
    bold: "font-bold" 
  };

export const ToolTipType = {
  primary: "bg-azure-radiance-500  flex text-white rounded-lg justify-between pl-3 pr-2 py-2 w-full font-semibold text-sm",
  secondary: "bg-slate-300 flex text-black rounded-lg justify-between pl-3 pr-2 py-2 w-full font-semibold",
  light: "bg-azure-radiance-600 flex text-white rounded-lg justify-between pl-3 pr-2 py-2 w-full font-semibold",
  basic: "bg-transparent flex text-white rounded-lg justify-between border-2 border-white pl-3 pr-2 py-2 w-full font-semibold",
  submit: "bg-green-500 flex cursor-pointer hover:bg-green-700 text-white  rounded-lg justify-between pl-3 pr-2 py-2 w-full font-semibold"
};


export const ToolTipVisibility = {
  visible: "visible",
  hidden: "invisible"
}

export const ToggleTheme = {
  primary: 'bg-blue-700 toggle-label block overflow-hidden rounded-full cursor-pointer',
  submit: 'bg-green-700 toggle-label block overflow-hidden rounded-full cursor-pointer',
  secondary: 'bg-gray-300 toggle-label block overflow-hidden rounded-full cursor-pointer',
}

export const ToggleSize = {

  small: "absolute block w-4 h-4 rounded-full bg-white p-1 appearance-none ",
  large: "w-6 h-6 absolute block rounded-full bg-white p-1 appearance-none ",

}

export const ToggleButtonSize = {
  small: "h-4",
  large: "h-6",
}

export const DropDownVisibility = {
  visible: "visible",
  hidden: "invisible"
}

export const DropDownButtonType = {
  primary: "bg-azure-radiance-500  text-white inline-flex justify-center relative rounded-lg cursor-pointer",
  secondary: "bg-white text-black inline-flex justify-center relative rounded-lg cursor-pointer",
  basic: "bg-transparent text-white inline-flex justify-center relative rounded-lg cursor-pointer border border-white",
  submit: "bg-green-500 text-white cursor-pointer inline-flex justify-center relative rounded-lg cursor-pointer"
};

export const DropDownButtonSize = {
small: "py-2 pl-4 pr-2 text-xs",
medium: "py-2.5 pl-4 pr-2 text-sm",
large: "py-3 pl-6 pr-4 text-lg",
extraLarge: "py-4 pl-8 pr-6 text-lg" 
};

export const DropDownOptionType = {
  primary: "bg-azure-radiance-500 min-w-full text-white origin-top-right absolute  z-10 mt-2 p-2 rounded-md shadow-lg",
  secondary: "bg-white text-black min-w-full origin-top-right absolute  z-10 mt-2 p-2 rounded-md shadow-lg",
  basic: "bg-transparent text-white min-w-full origin-top-right absolute  z-10 mt-2 p-2 rounded-md shadow-lg border-2 border-white",
  submit: "bg-green-500 text-white min-w-full cursor-pointer origin-top-right absolute  z-10 mt-2 p-2 rounded-md shadow-lg"
};

export const DropDownOptionSize = {
small: "py-2 px-4 text-xs",
medium: "py-2.5 px-4.5 text-base",
large: "py-3 px-6 text-lg",
};


export const DropDownButtonWeight = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold" 
};

export const InputType = {
  primary: "text-white appearance-none w-full bg-transparent mt-2 mr-2 rounded-lg flex justify-center border border-azure-radiance-500 focus:outline-none focus:border-azure-radiance-500",
  secondary: "bg-transparent appearance-none w-full text-white mr-2 rounded-lg flex justify-center border border-white mt-2 focus:outline-none focus:border-white",
  submit: "text-white w-full appearance-none bg-transparent mr-2 rounded-lg flex mt-2  justify-center border border-green-500 focus:outline-none focus:border-green-500"
};

export const InputSize = {
  small: "px-2 py-2 text-xs",
  large: "px-3 py-3 text-lg",
  extraLarge: "py-4 py-4 text-lg" 
  
};

export const InputWidth = {
  single: "",
  multiple:"w-1/2"
}

export const TagsType = {
  primary: "bg-blue-700  my-auto text-white h-fit  rounded-md  justify-center",
  secondary: "bg-buddha-gold-600  my-auto text-white h-fit  rounded-md  justify-center",
  basic: "bg-transparent text-white  my-auto h-fit  rounded-md  justify-center border-white",
  submit: "bg-green-700  text-white h-fit  my-auto  rounded-md  justify-center"
};

export const TagsSize = {
extraSmall: "px-1 py-0.5 text-xs",
small: "px-1 py-0.5 text-sm",
large: "px-1.5 py-1  text-base",
extraLarge: "p-2 text-large" 
};

export const TagsWeight = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold" 
};

export const BioNameSize = {
  small: "lg:ml-4 text-white text-sm my-auto",
  normal: "lg:ml-4 text-white text-base my-auto",
  large: "lg:ml-4 text-white text-lg my-auto",
}

export const BioNameWeight = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
}

export const SuccessListSize = {
  normal: "ml-4 text-white text-base mx-auto my-auto",
  large: "ml-4 text-white text-lg mx-auto my-auto",
}

export const MenuItemsTextWeight = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold"
}

export const MenuItemsTextSize = {
  small: "text-xs",
  medium: "text-sm",
  large: "text-base",
  extraLarge: "text-lg"
}

export const MenuItemsTextType = {
  primary: "text-white ml-2",
  secondary: "text-gray-500 ml-2",
}

export const ImageToolTipWidth = {
  small: "w-80 lg:w-44 ",
  medium: "w-80 lg:w-52 ",
  
 
}
export const ImageToolTipHeight = {
  small: "h-72 lg:h-40",
  medium: "h-72  lg:h-48",
  
 
}

