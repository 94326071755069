import React from 'react';
import './App.css';
import './assets/styles/output.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ArNFT from "./Pages/ArNFT";
import ArPreview from "./Pages/ArPreview";

function App() {

  return (

    <BrowserRouter>
      <div className=" h-auto flex items-start bg-black flex-col flex-wrap">
        <Routes>
          <Route index path="/" element={
            <div className='h-screen w-full  bg-black'>
              <div className=" h-auto flex items-start bg-black flex-col flex-wrap">
                <div className='h-fit w-full  bg-black'>
                  <ArNFT />
                </div>
              </div>
            </div>
          } />
          <Route index path="/preview/:id" element={
            <div className='h-screen w-full  bg-black'>
              <div className=" h-auto flex items-start bg-black flex-col flex-wrap">
                <div className='h-fit w-full  bg-black'>
                  <ArPreview />
                </div>
              </div>
            </div>
          } />
        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;
