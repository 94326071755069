
const parentNodesInfo = [
    {
        id: 1,
        name: 'first',
        model_ref: 'Bodypart',
        preview_image: '',
        icon: '/menu_icon/side_icon/body.png'
    },
    {
        id: 2,
        name: 'first',
        model_ref: 'Eye',
        preview_image: '',
        icon: '/menu_icon/side_icon/glasses.png'
    },
    {
        id: 3,
        name: 'first',
        model_ref: 'Glove',
        preview_image: '',
        icon: '/menu_icon/side_icon/faces.png'
    },
    {
        id: 4,
        name: 'first',
        model_ref: 'Headpart',
        preview_image: '',
        icon: '/menu_icon/side_icon/redhat.png'
    },
    {
        id: 5,
        name: 'first',
        model_ref: 'MainBody',
        preview_image: '',
        icon: '/menu_icon/side_icon/body.png'
    },
    {
        id: 6,
        name: 'first',
        model_ref: 'MothandNooses',
        preview_image: '',
        icon: '/menu_icon/side_icon/faces.png'
    },
    {
        id: 7,
        name: 'first',
        model_ref: 'Tail',
        preview_image: '',
        icon: '/menu_icon/side_icon/shirt.png'
    },

]


const meshNodesInfo = [
    {
        id: 11,
        parentId: 1,
        name: 'first',
        child_ref: 'Bodypart01',
        preview_image: '/thumbnail/Bodypart/Image0003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'blue_scraf',
        }

    },
    {
        id: 12,
        parentId: 1,
        name: 'second',
        child_ref: 'Bodypart02',
        preview_image: '/thumbnail/Bodypart/Image0010.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'red_color',
        }

    },
    {
        id: 13,
        parentId: 1,
        name: 'third',
        child_ref: 'Bodypart03',
        preview_image: '/thumbnail/Bodypart/Image0006.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'pink_belt',
        }
    },
    {
        id: 14,
        parentId: 1,
        name: 'fourth',
        child_ref: 'Bodypart04',
        preview_image: '/thumbnail/Bodypart/Image0005.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'greeen_tie',
        }
    },
    {
        id: 15,
        parentId: 1,
        name: 'fifth',
        child_ref: 'Bodypart05',
        preview_image: '/thumbnail/Bodypart/Image0007.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'star_sticker',
        }
    },
    {
        id: 16,
        parentId: 1,
        name: 'sixth',
        child_ref: 'Bodypart06',
        preview_image: '/thumbnail/Bodypart/Image0002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'vshape_sticker',
        }
    },
    {
        id: 17,
        parentId: 1,
        name: 'seventh',
        child_ref: 'Bodypart07',
        preview_image: '/thumbnail/Bodypart/Image0008.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'purple_belt',
        }

    },
    {
        id: 18,
        parentId: 1,
        name: 'eigth',
        child_ref: 'Bodypart09',
        preview_image: '/thumbnail/Bodypart/Image0009.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'dart_sticker',
        }
    },
    {
        id: 19,
        parentId: 1,
        name: 'nine',
        child_ref: 'Bodypart09',
        preview_image: '/thumbnail/Bodypart/Image0001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'red_love',
        }
    },
    {
        id: 20,
        parentId: 1,
        name: 'tenth',
        child_ref: 'Bodypart10',
        preview_image: '/thumbnail/Bodypart/Image0004.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'skull_ring',
        }

    },
    {
        id: 21,
        parentId: 2,
        name: 'eleven',
        child_ref: 'Eye01',
        preview_image: '/thumbnail/Eye/Image0002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'circle_eye',
        }
    },
    {
        id: 22,
        parentId: 2,
        name: 'twelve',
        child_ref: 'Eye02',
        preview_image: '/thumbnail/Eye/Image0006.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'semicircle_eye',
        }
    },
    {
        id: 23,
        parentId: 2,
        name: 'thirteen',
        child_ref: 'Eye03',
        preview_image: '/thumbnail/Eye/Image0012.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'cartoon_eye',
        }
    },
    {
        id: 24,
        parentId: 2,
        name: 'fourteen',
        child_ref: 'Eye04',
        preview_image: '/thumbnail/Eye/Image0001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'lower_eye',
        }
    },
    {
        id: 25,
        parentId: 2,
        name: 'fifteen',
        child_ref: 'Eye05',
        preview_image: '/thumbnail/Eye/Image0003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'one_eye',
        }
    },
    {
        id: 26,
        parentId: 2,
        name: 'sixteen',
        child_ref: 'Eye06',
        preview_image: '/thumbnail/Eye/Image0005.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'cross_eye',
        }
    },
    {
        id: 27,
        parentId: 2,
        name: 'seventeen',
        child_ref: 'Eye07',
        preview_image: '/thumbnail/Eye/Image0004.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'glass_eye',
        }
    },
    {
        id: 28,
        parentId: 2,
        name: 'eighteen',
        child_ref: 'Eye08',
        preview_image: '/thumbnail/Eye/Image0009.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'love_eye',
        }
    },
    {
        id: 29,
        parentId: 2,
        name: 'nineteen',
        child_ref: 'Eye09',
        preview_image: '/thumbnail/Eye/Image0014.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'oval_eye',
        }
    },
    {
        id: 30,
        parentId: 2,
        name: 'twenty',
        child_ref: 'Eye09',
        preview_image: '/thumbnail/Eye/Image0011.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'weight_eye',
        }
    },
    {
        id: 31,
        parentId: 2,
        name: 'twentyone',
        child_ref: 'Eye10',
        preview_image: '/thumbnail/Eye/Image0015.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'mask_eye',
        }
    },
    {
        id: 32,
        parentId: 2,
        name: 'twentytwo',
        child_ref: 'Eye11',
        preview_image: '/thumbnail/Eye/Image0010.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'cap_eye',
        }
    },
    {
        id: 33,
        parentId: 2,
        name: 'twentythree',
        child_ref: 'Eye12',
        preview_image: '/thumbnail/Eye/Image0007.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'sunglass_eye',
        }
    },
    {
        id: 34,
        parentId: 2,
        name: 'twentyfour',
        child_ref: 'Eye13',
        preview_image: '/thumbnail/Eye/Image0008.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'waterglass_eye',
        }
    },
    {
        id: 35,
        parentId: 2,
        name: 'twentyfive',
        child_ref: 'Eye13',
        preview_image: '/thumbnail/Eye/Image0013.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'robot_eye',
        }
    },
    {
        id: 36,
        parentId: 3,
        name: 'twentysix',
        child_ref: 'Glove01',
        preview_image: '/thumbnail/Glove/Image0010.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'red_gloves',
        }
    },
    {
        id: 37,
        parentId: 3,
        name: 'twentyseven',
        child_ref: 'Glove02',
        preview_image: '/thumbnail/Glove/Image0002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'blue_gloves',
        }
    },
    {
        id: 38,
        parentId: 3,
        name: 'twentyeight',
        child_ref: 'Glove03',
        preview_image: '/thumbnail/Glove/Image0004.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'black_gloves',
        }
    },
    {
        id: 39,
        parentId: 3,
        name: 'twentynine',
        child_ref: 'Glove04',
        preview_image: '/thumbnail/Glove/Image0007.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'needle_gloves',
        }
    },
    {
        id: 40,
        parentId: 3,
        name: 'thirty',
        child_ref: 'Glove05',
        preview_image: '/thumbnail/Glove/Image0008.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'love_gloves',
        }
    },
    {
        id: 41,
        parentId: 3,
        name: 'thirtyone',
        child_ref: 'Glove06',
        preview_image: '/thumbnail/Glove/Image0009.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'cone_gloves',
        }
    },
    {
        id: 42,
        parentId: 3,
        name: 'thirtytwo',
        child_ref: 'Glove07',
        preview_image: '/thumbnail/Glove/Image0003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'cutter_gloves',
        }
    },
    {
        id: 43,
        parentId: 3,
        name: 'thirtythree',
        child_ref: 'Glove08',
        preview_image: '/thumbnail/Glove/Image0001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'monster_gloves',
        }
    },
    {
        id: 44,
        parentId: 3,
        name: 'thirtyfour',
        child_ref: 'Glove09',
        preview_image: '/thumbnail/Glove/Image0006.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'rock_gloves',
        }
    },
    {
        id: 45,
        parentId: 3,
        name: 'thirtyfive',
        child_ref: 'Glove10',
        preview_image: '/thumbnail/Glove/Image0005.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'lion_gloves',
        }
    },
    {
        id: 46,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart001',
        preview_image: '/thumbnail/Headpart/Image001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'fox_ear',
        }

    },
    {
        id: 47,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart002',
        preview_image: '/thumbnail/Headpart/Image002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'bunny_ear',
        }
    },
    {
        id: 48,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart003',
        preview_image: '/thumbnail/Headpart/Image003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'god_ear',
        }
    },
    {
        id: 49,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart004',
        preview_image: '/thumbnail/Headpart/Image004.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'curly_hair',
        }
    },
    {
        id: 50,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart005',
        preview_image: '/thumbnail/Headpart/Image005.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'grass_hair',
        }
    },
    {
        id: 51,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart006',
        preview_image: '/thumbnail/Headpart/Image006.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'horn',
        }
    },
    {
        id: 52,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart007',
        preview_image: '/thumbnail/Headpart/Image007.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'single_horn',
        }
    },
    {
        id: 53,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart008',
        preview_image: '/thumbnail/Headpart/Image008.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'fox_ear',
        }
    },
    {
        id: 54,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart009',
        preview_image: '/thumbnail/Headpart/Image009.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'color_horn',
        }
    },
    {
        id: 55,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart010',
        preview_image: '/thumbnail/Headpart/Image010.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'dense_grass_hair',
        }
    },
    {
        id: 56,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart011',
        preview_image: '/thumbnail/Headpart/Image011.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'brthday_cap',
        }
    },
    {
        id: 57,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart012',
        preview_image: '/thumbnail/Headpart/Image012.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'trophy_cap',
        }
    },
    {
        id: 58,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart013',
        preview_image: '/thumbnail/Headpart/Image013.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'army_cap',
        }
    },
    {
        id: 59,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart014',
        preview_image: '/thumbnail/Headpart/Image014.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'chief_cap',
        }
    },
    {
        id: 60,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart015',
        preview_image: '/thumbnail/Headpart/Image015.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'star_cap',
        }
    },
    {
        id: 61,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart016',
        preview_image: '/thumbnail/Headpart/Image016.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'baloon_cap',
        }
    },
    {
        id: 62,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart017',
        preview_image: '/thumbnail/Headpart/Image017.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'winter_cap',
        }
    },
    {
        id: 63,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart018',
        preview_image: '/thumbnail/Headpart/Image018.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'fox_ear',
        }
    },
    {
        id: 64,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart019',
        preview_image: '/thumbnail/Headpart/Image019.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'girl_hair_cap',
        }
    },
    {
        id: 65,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart020',
        preview_image: '/thumbnail/Headpart/Image020.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'mad_girl_cap',
        }
    },
    {
        id: 66,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart021',
        preview_image: '/thumbnail/Headpart/Image021.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'unicorn_cap',
        }
    },

    {
        id: 67,
        parentId: 5,
        name: 'fiftyseven',
        child_ref: 'MainBody01',
        preview_image: '/thumbnail/MainBody/Image001.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#ffffff',
            color_2: '#e2af18'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'yellow_body',
        }
    },
    {
        id: 68,
        parentId: 5,
        name: 'fiftyeight',
        child_ref: 'MainBody02',
        preview_image: '/thumbnail/MainBody/Image002.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#fcfcfc',
            color_2: '#ff6bcb'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'pink_body',
        }
    },
    {
        id: 69,
        parentId: 5,
        name: 'fiftynine',
        child_ref: 'MainBody03',
        preview_image: '/thumbnail/MainBody/Image003.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#fcfcfc',
            color_2: '#55aeff'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'blue_body',
        }
    },
    {
        id: 70,
        parentId: 5,
        name: 'sixty',
        child_ref: 'MainBody04',
        preview_image: '/thumbnail/MainBody/Image004.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#fcfcfc',
            color_2: '#a8ff4e'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'green_body',
        }
    },
    {
        id: 71,
        parentId: 5,
        name: 'sixtyone',
        child_ref: 'MainBody05',
        preview_image: '/thumbnail/MainBody/Image005.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#fcfcfc',
            color_2: '#7d44df'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'purple_body',
        }
    },
    {
        id: 72,
        parentId: 5,
        name: 'sixtytwo',
        child_ref: 'MainBody06',
        preview_image: '/thumbnail/MainBody/Image006.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#fcfcfc',
            color_2: '#ff992d'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'orange_body',
        }
    },

    {
        id: 73,
        parentId: 6,
        name: 'sixtythree',
        child_ref: 'Mouth01',
        preview_image: '/thumbnail/MouthandNoses/Image001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'smile',
        }

    },
    {
        id: 74,
        parentId: 6,
        name: 'sixtyfour',
        child_ref: 'Mouth02',
        preview_image: '/thumbnail/MouthandNoses/Image002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'sad',
        }
    },
    {
        id: 75,
        parentId: 6,
        name: 'sixtyfive',
        child_ref: 'Mouth03',
        preview_image: '/thumbnail/MouthandNoses/Image003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'tooth',
        }
    },
    {
        id: 76,
        parentId: 6,
        name: 'sixtysix',
        child_ref: 'Mouth04',
        preview_image: '/thumbnail/MouthandNoses/Image004.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'toothEye',
        }
    },
    {
        id: 77,
        parentId: 6,
        name: 'sixtyseven',
        child_ref: 'Mouth05',
        preview_image: '/thumbnail/MouthandNoses/Image005.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'bird_mouth',
        }
    },
    {
        id: 78,
        parentId: 6,
        name: 'sixtyeight',
        child_ref: 'Mouth06',
        preview_image: '/thumbnail/MouthandNoses/Image006.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'angry_tooth',
        }
    },
    {
        id: 79,
        parentId: 6,
        name: 'sixtynine',
        child_ref: 'Mouth07',
        preview_image: '/thumbnail/MouthandNoses/Image007.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'dog_mouth',
        }
    },
    {
        id: 80,
        parentId: 6,
        name: 'seventy',
        child_ref: 'Mouth08',
        preview_image: '/thumbnail/MouthandNoses/Image008.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'tooth_smile',
        }
    },
    {
        id: 81,
        parentId: 6,
        name: 'seventyone',
        child_ref: 'Mouth09',
        preview_image: '/thumbnail/MouthandNoses/Image009.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'shut_mouth',
        }
    },
    {
        id: 82,
        parentId: 6,
        name: 'seventytwo',
        child_ref: 'Mouth010',
        preview_image: '/thumbnail/MouthandNoses/Image010.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'no_mouth',
        }
    },

    {
        id: 83,
        parentId: 6,
        name: 'seventythree',
        child_ref: 'Noose11',
        preview_image: '/thumbnail/MouthandNoses/Image011.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'eye_out',
        }
    },
    {
        id: 84,
        parentId: 6,
        name: 'seventyfour',
        child_ref: 'Noose12',
        preview_image: '/thumbnail/MouthandNoses/Image012.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'cone',
        }
    },
    {
        id: 85,
        parentId: 6,
        name: 'seventyfive',
        child_ref: 'Noose13',
        preview_image: '/thumbnail/MouthandNoses/Image013.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'circle_hole',
        }
    },
    {
        id: 86,
        parentId: 6,
        name: 'seventysix',
        child_ref: 'Noose14',
        preview_image: '/thumbnail/MouthandNoses/Image014.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'toy',
        }
    },
    {
        id: 87,
        parentId: 6,
        name: 'seventyseven',
        child_ref: 'Noose15',
        preview_image: '/thumbnail/MouthandNoses/Image015.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'tail',
        }
    },

    {
        id: 88,
        parentId: 7,
        name: 'seventyeight',
        child_ref: 'Tail01',
        preview_image: '/thumbnail/Tail/Image001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'normal_tail',
        }
    },
    {
        id: 89,
        parentId: 7,
        name: 'seventynine',
        child_ref: 'Tail02',
        preview_image: '/thumbnail/Tail/Image002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'horn_tail',
        }
    },
    {
        id: 90,
        parentId: 7,
        name: 'eighty',
        child_ref: 'Tail03',
        preview_image: '/thumbnail/Tail/Image003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'circular_tail',
        }
    },
    {

        id: 91,
        parentId: 7,
        name: 'eightyone',
        child_ref: 'Tail04',
        preview_image: '/thumbnail/Tail/Image004.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'bubbles_tail',
        }
    },
    {
        id: 92,
        parentId: 7,
        name: 'eightytwo',
        child_ref: 'Tail05',
        preview_image: '/thumbnail/Tail/Image005.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'crab_tail',
        }
    },
    {
        id: 93,
        parentId: 7,
        name: 'eightythree',
        child_ref: 'Tail06',
        preview_image: '/thumbnail/Tail/Image006.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'stone_tail',
        }
    },
    {
        id: 94,
        parentId: 7,
        name: 'eightyfour',
        child_ref: 'Tail07',
        preview_image: '/thumbnail/Tail/Image007.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'shark_tail',
        }
    },
    {
        id: 95,
        parentId: 7,
        name: 'eightyfour',
        child_ref: 'Tail08',
        preview_image: '/thumbnail/Tail/Image008.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'cat_tail',
        }
    },
]


const defaultNodes = [
    {
        id: 11,
        parentId: 1,
        name: 'first',
        child_ref: 'Bodypart01',
        preview_image: '/thumbnail/Bodypart/Image0003.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Bodypart',
            value: 'blue_scraf',
        }
    },
    {
        id: 21,
        parentId: 2,
        name: 'eleven',
        child_ref: 'Eye01',
        preview_image: '/thumbnail/Eye/Image0002.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Eye',
            value: 'circle_eye',
        }
    },
    {
        id: 36,
        parentId: 3,
        name: 'twentysix',
        child_ref: 'Glove01',
        preview_image: '/thumbnail/Glove/Image0010.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Gloves',
            value: 'red_gloves',
        }
    },
    {
        id: 46,
        parentId: 4,
        name: 'thirtyfive',
        child_ref: 'Headpart001',
        preview_image: '/thumbnail/Headpart/Image001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Headpart',
            value: 'fox_ear',
        }
    },
    {
        id: 67,
        parentId: 5,
        name: 'fiftyseven',
        child_ref: 'MainBody01',
        preview_image: '/thumbnail/MainBody/Image001.png',
        model: 'RickAnimated.glb',
        background_color: {
            color_1: '#ffffff',
            color_2: '#e2af18'
        },
        metadata: {
            trait_type: 'MainBody',
            value: 'yellow_body',
        }
    },
    {
        id: 73,
        parentId: 6,
        name: 'fiftyseven',
        child_ref: 'Mouth01',
        preview_image: '/thumbnail/MouthandNoses/Image001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'MothandNooses',
            value: 'smile',
        }
    },
    {
        id: 88,
        parentId: 7,
        name: 'seventyseven',
        child_ref: 'Tail01',
        preview_image: '/thumbnail/Tail/Image001.png',
        model: 'RickAnimated.glb',
        metadata: {
            trait_type: 'Tail',
            value: 'normal_tail',
        }
    }
]


let model_data_obj = {
    parent: parentNodesInfo,
    child: meshNodesInfo,
    initial_nodes: defaultNodes
}

export default model_data_obj;