import React from 'react';
import WebGL from 'three/examples/jsm/capabilities/WebGL.js';
import { Viewer } from './viewer.js';
import RootContext from "../../providers/RootProvider";
import { TailSpin } from 'react-loader-spinner'
import model_data_obj from "./model_data";
import Button from '../../../src/Components/Button';
if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
    console.error('The File APIs are not fully supported in this browser.');
} else if (!WebGL.isWebGLAvailable()) {
    console.error('WebGL is not supported in this browser.');
}

const enviornment = {
    // MODEL_ENDPOINT: 'http://localhost:3000'
    // MODEL_ENDPOINT: 'https://seedify.artonar.xyz'
    MODEL_ENDPOINT: 'https://seedify-ar.s3.amazonaws.com'
}

let iphone = false;
let android = false;
let mobileDevice = false;

function getDeviceType() {
    let ua = navigator.userAgent.toLowerCase();
    android = ua.indexOf("android") > -1;
    iphone = /iPhone/i.test(navigator.userAgent) && /Safari/i.test(navigator.userAgent);

    if (iphone || android) {
        mobileDevice = true;
        console.info('Script-tag: Mobile Device');
        if (iphone) {
            console.log("Device type is iOS");
        } else {
            console.log("Device type is Android");
        }
    } else {
        mobileDevice = false;
        console.log("Not a mobile device")
    }
}




function ArNFT({ ...props }) {
    const {
        imageProcessArray, setImageProcessArray,
        currentActivePage, setCurrentActivePage,
        nodesSelectedForVariation, setNodesSelectedForVariation
    } = React.useContext(RootContext);
    const [parentId, setParentId] = React.useState(1);
    const [selectImgBorder, setSelectImgBorder] = React.useState(11);
    const [isMobileDevice, setIsMobileDevice] = React.useState(mobileDevice);
    const [loader, setLoader] = React.useState(true);
    const [documentViewer, setDocumentViewer] = React.useState(null);
    const [modelNodesEnabled, setModelNodesEnabled] = React.useState(model_data_obj.initial_nodes); // this is to store all checked nodes in the GUI option
    const [modelLoaderBackgorund, setModelLoaderBackgorund] = React.useState('radial-gradient(#ffffff, #e2af18)');
    const [options, setOptions] = React.useState({
        kiosk: false,
        model: '/models/variation1.glb',
        preset: '',
        cameraPosition: null
    });

    const [activeNodes, setActiveNodes] = React.useState(model_data_obj.initial_nodes); // this is for storing all active nodes info from each part
    const [modelNodesInfo, setModelNodesInfo] = React.useState(null);  // this is for storing nodes information of the mesh
    const [modalQR, setModalQR] = React.useState(false);
    console.log("Deafult values : ", model_data_obj.initial_nodes);

    /**
     * Passes a model to the viewer, given file and resources.
     * @param  {File|string} rootFile
     * @param  {string} rootPath
     * @param  {Map<string, File>} fileMap
     */
    const intiModelView = (model_view_obj, rootFile, rootPath, fileMap) => {
        let viewerLoader = model_view_obj || documentViewer;
        if (viewerLoader) {
            viewerLoader.clear();
        }
        // setModelLoader(viewerLoader);

        const fileURL = typeof rootFile === 'string' ? rootFile : URL.createObjectURL(rootFile);
        const cleanup = () => {
            setLoader(false);
            if (typeof rootFile === 'object') {
                URL.revokeObjectURL(fileURL);
            }
        };

        viewerLoader.load(fileURL, rootPath, fileMap).catch((e) => onError(e)).then((gltf) => {
            if (!options.kiosk) {
                // this.validationCtrl.validate(fileURL, rootPath, fileMap, gltf);
            }
            let variationObjInfo = viewerLoader.getNodesInfo();
            let setVisiblityResponse = viewerLoader.setVisiblityFalse(variationObjInfo);
            setModelNodesInfo(variationObjInfo);
            setLoader(false);
            cleanup();
            viewerLoader.setVisiblityTrue_MultiNode(activeNodes);
        });
    }

    /**
     * @param  {Error} error
     */
    const onError = (error) => {
        let message = (error || {}).message || error.toString();
        if (message.match(/ProgressEvent/)) {
            message = 'Unable to retrieve this file. Check JS console and browser network tab.';
        } else if (message.match(/Unexpected token/)) {
            message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
        } else if (error && error.target && error.target instanceof Image) {
            message = 'Missing texture: ' + error.target.src.split('/').pop();
        }
        window.alert(message);
        console.error(error);
    }

    /**
     * Init variable and enviornment
     */
    React.useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        let documentBody = document.body;
        let viewerElement = window.document.getElementById('viewer');
        viewerElement.classList.add('viewer');

        let viewerLoader = new Viewer(viewerElement, options);
        setDocumentViewer(viewerLoader);

        intiModelView(viewerLoader, options.model, '', new Map());
    }, []);

    React.useEffect(() => {
        setNodesSelectedForVariation(model_data_obj.initial_nodes);
    }, []);



    const onViewOnArButtonClick = async () => {
        console.log("Hello world")
        let gltfPath = `${enviornment.MODEL_ENDPOINT}/models/variation1.gltf`;
        let usdzPath = `${enviornment.MODEL_ENDPOINT}/models/variation1.usdz`;
        let qrCodeGenerateObj = {
            "usdz": usdzPath,
            "gltf": gltfPath,
        }

        if (iphone || android) {
            addViewInSpaceButton(qrCodeGenerateObj.usdz, qrCodeGenerateObj.gltf);
        } else {
            alert("Some error occured, please try again!")
        }
    }

    function addViewInSpaceButton(usdz_url, gltf_url) {
        let uploadedFileIos = usdz_url;
        let uploadedFileAndroid = gltf_url;

        // creating link 
        let callToAction = encodeURI("Click to 3d meta");
        let link = document.createElement("a");
        link.href = uploadedFileIos + "#callToAction=" + callToAction;
        link.id = "ar-link";
        link.rel = "ar";
        if (android) {
            link.href = "intent://arvr.google.com/scene-viewer/1.0?file=" + uploadedFileAndroid +
                "#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
        }


        // creating Image Inside Link just for event  purpose
        let imageAr = document.createElement("IMG");
        imageAr.setAttribute("src", "https://arspar.s3.eu-central-1.amazonaws.com/arspar/view-in-ar.png");
        imageAr.setAttribute("width", "10");
        imageAr.setAttribute("height", "10");
        imageAr.setAttribute("alt", "View In AR");
        link.appendChild(imageAr);

        link.addEventListener("message", function (event) {
            console.log({ event });
            if (event.data == "_apple_ar_quicklook_button_tapped") {
                //            addItemToCart();
            }
        }, false);

        // link.click();
        imageAr.click();
    }



    React.useEffect(() => {
        getDeviceType();
    }, []);




    return (
        <div className="h-screen w-full mx-auto relative overflow-auto" {...props}>
            {loader ? <div className="fixed w-full h-full top-0 left-0 flex items-center backdrop-blur-lg justify-center z-20">
                <div className="absolute w-full h-full opacity-50"></div>
                <TailSpin color="#00BFFF" height={88} width={88} />
            </div> : <></>}

            {modalQR ? <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-20">
                <div className="absolute w-full h-full opacity-50"></div>
                <div className="bg-black w-auto mx-auto rounded-lg p-4 z-50 overflow-y-auto">
                    <div className="flex flex-row justify-end cursor-pointer z-50 mr-1" onClick={() => setModalQR(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="39.209" height="39.209" viewBox="0 0 39.209 39.209">
                            <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M22.98,3.375a19.6,19.6,0,1,0,19.6,19.6A19.6,19.6,0,0,0,22.98,3.375Zm4.967,26.7L22.98,25.11l-4.967,4.967a1.506,1.506,0,1,1-2.13-2.13l4.967-4.967-4.967-4.967a1.506,1.506,0,0,1,2.13-2.13l4.967,4.967,4.967-4.967a1.506,1.506,0,1,1,2.13,2.13L25.11,22.98l4.967,4.967a1.513,1.513,0,0,1,0,2.13A1.5,1.5,0,0,1,27.947,30.077Z" transform="translate(-3.375 -3.375)" fill="#fff" />
                        </svg>

                    </div>
                    <div className='pt-12 px-16 pb-16'>
                        <div className=' rounded-lg  w-80 lg:border-none  lg:w-96 mx-auto lg:mr-0 lg:ml-auto flex flex-col'>

                            <img src="https://seedify.artonar.xyz/qrcode/variation1.png" alt='' className='h-64 w-64 mx-auto' />

                        </div>
                    </div>

                </div>
            </div> : <></>}


            <div className='px-4 py-4 w-full'>
                <div className="w-full bg-gray-200 h-1">
                    <div className="bg-blue-600 h-1 w-2/4"></div>
                </div>
            </div>

            <div className='w-full'>
                {/* {img ? <img src={img} alt='' className='w-40 h-40 mx-auto' /> : <></>} */}
                <div
                    id="viewer"
                    className='w-screen h-screen  mx-auto canvas-background-gradient'
                    style={{ backgroundImage: modelLoaderBackgorund }}
                >


                </div>


            </div>
            <div className='w-fit m-4 absolute top-8 left-2'>
                {mobileDevice ? <Button icon={'window'} type='dark' onClick={() => onViewOnArButtonClick(true)} >
                    View In AR
                </Button> : <Button icon={'window'} type='dark' onClick={() => setModalQR(true)} >
                    View In AR
                </Button>}
            </div>



        </div>
    )
};

export default ArNFT;

