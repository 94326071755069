
import React from "react";
import '../../styles/output.css';
const Icons = ({ icon, bgColor,height, width, ...props }) => {
    const fillColor = bgColor ? bgColor : '';

    return (
        <>
            {(() => {
                switch (icon) {
                    case "downarrow": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" xmlns="http://www.w3.org/2000/svg" width="11.969" height="6.843" viewBox="0 0 11.969 6.843">
                                <path className={`${fillColor}`} id="Icon_ionic-ios-arrow-down-2" data-name="Icon ionic-ios-arrow-down" d="M12.174,16.027,16.7,11.5a.852.852,0,0,1,1.208,0,.863.863,0,0,1,0,1.212L12.78,17.841a.854.854,0,0,1-1.18.025L6.437,12.713A.855.855,0,1,1,7.645,11.5Z" transform="translate(-6.188 -11.246)" fill="#fff" />
                            </svg>
                        </div>
                    );

                    case "gallery": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="18.634" height="13.976" viewBox="0 0 18.634 13.976">
                                <path className={`${fillColor}`} id="Icon_awesome-image" data-name="Icon awesome-image" d="M16.887,18.476H1.747A1.747,1.747,0,0,1,0,16.729V6.247A1.747,1.747,0,0,1,1.747,4.5h15.14a1.747,1.747,0,0,1,1.747,1.747V16.729A1.747,1.747,0,0,1,16.887,18.476ZM4.076,6.538A2.038,2.038,0,1,0,6.114,8.576,2.038,2.038,0,0,0,4.076,6.538ZM2.329,16.146H16.3V12.07L13.12,8.885a.437.437,0,0,0-.618,0L7.57,13.817,5.55,11.8a.437.437,0,0,0-.618,0l-2.6,2.6Z" transform="translate(0 -4.5)" fill="#fff" />
                            </svg>
                        </div>

                    );

                    case "loop": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.994" height="15.29" viewBox="0 0 18.994 15.29">
                                <g id="Icon_feather-refresh-cw" data-name="Icon feather-refresh-cw" transform="translate(-0.086 -3.471)">
                                    <path className={`${fillColor}`} id="Path_2866" data-name="Path 2866" d="M29.909,6v4.409H25.5" transform="translate(-12.242 -0.763)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path className={`${fillColor}`} id="Path_2867" data-name="Path 2867" d="M1.5,25.409V21H5.909" transform="translate(0 -8.415)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path className={`${fillColor}`} id="Path_2868" data-name="Path 2868" d="M3.345,8.911A6.614,6.614,0,0,1,14.257,6.442l3.41,3.2M1.5,12.585l3.41,3.2A6.614,6.614,0,0,0,15.823,13.32" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>

                        </div>

                    );

                    case "file": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="7.619" height="10.159" viewBox="0 0 7.619 10.159">
                                <path className={`${fillColor}`} id="Icon_awesome-file-download" data-name="Icon awesome-file-download" d="M4.445,2.7V0H.476A.475.475,0,0,0,0,.476V9.683a.475.475,0,0,0,.476.476H7.143a.475.475,0,0,0,.476-.476V3.175h-2.7A.478.478,0,0,1,4.445,2.7ZM5.962,6.892l-1.913,1.9a.338.338,0,0,1-.477,0l-1.913-1.9a.317.317,0,0,1,.223-.543H3.175V4.762a.317.317,0,0,1,.317-.317h.635a.317.317,0,0,1,.317.317V6.35H5.738A.318.318,0,0,1,5.962,6.892ZM7.481,2.083,5.538.139A.476.476,0,0,0,5.2,0H5.08V2.54h2.54V2.419A.475.475,0,0,0,7.481,2.083Z" fill="#fff" />
                            </svg>


                        </div>
                    )

                    case "largefile": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.366" height="15.155" viewBox="0 0 11.366 15.155">
                                <path className={`${fillColor}`} id="Icon_awesome-file-download" data-name="Icon awesome-file-download" d="M6.63,4.026V0H.71A.709.709,0,0,0,0,.71V14.444a.709.709,0,0,0,.71.71h9.945a.709.709,0,0,0,.71-.71V4.736H7.341A.712.712,0,0,1,6.63,4.026Zm2.263,6.256L6.039,13.114a.5.5,0,0,1-.712,0L2.474,10.282a.473.473,0,0,1,.333-.81H4.736V7.1a.473.473,0,0,1,.474-.474h.947A.473.473,0,0,1,6.63,7.1V9.472H8.56A.474.474,0,0,1,8.893,10.282Zm2.266-7.174-2.9-2.9A.71.71,0,0,0,7.758,0H7.577V3.789h3.789V3.608A.708.708,0,0,0,11.159,3.108Z" fill="#fff" />
                            </svg>

                        </div>
                    )

                    case "window": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg id="Icon_ionic-md-qr-scanner" data-name="Icon ionic-md-qr-scanner" xmlns="http://www.w3.org/2000/svg" width="12.181" height="11.244" viewBox="0 0 12.181 11.244">
                                <path className={`${fillColor}`} id="Path_2862" data-name="Path 2862" d="M4.781,6.263a.35.35,0,0,1,.357-.357H7.123V4.5H5.167A1.778,1.778,0,0,0,3.375,6.263V8.248H4.781Z" transform="translate(-3.375 -4.5)" fill="#fff" />
                                <path className={`${fillColor}`} id="Path_2863" data-name="Path 2863" d="M25.6,4.5H23.625V5.906h1.968a.364.364,0,0,1,.375.357V8.248h1.406V6.263A1.76,1.76,0,0,0,25.6,4.5Z" transform="translate(-15.192 -4.5)" fill="#fff" />
                                <path className={`${fillColor}`} id="Path_2864" data-name="Path 2864" d="M25.968,24.456a.35.35,0,0,1-.357.357H23.625v1.435H25.61a1.778,1.778,0,0,0,1.763-1.792V22.5H25.968Z" transform="translate(-15.192 -15.004)" fill="#fff" />
                                <path className={`${fillColor}`} id="Path_2865" data-name="Path 2865" d="M5.138,24.813a.35.35,0,0,1-.357-.357V22.5H3.375v1.956a1.8,1.8,0,0,0,1.792,1.792H7.123V24.813Z" transform="translate(-3.375 -15.004)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "edit": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.046" height="16.038" viewBox="0 0 18.046 16.038">
                                <path className={`${fillColor}`} id="Icon_awesome-edit" data-name="Icon awesome-edit" d="M12.614,2.611,15.44,5.437a.306.306,0,0,1,0,.432L8.6,12.712l-2.907.323a.609.609,0,0,1-.674-.674l.323-2.907,6.843-6.843A.306.306,0,0,1,12.614,2.611Zm5.076-.717L16.16.364a1.225,1.225,0,0,0-1.729,0L13.322,1.473a.306.306,0,0,0,0,.432l2.826,2.826a.306.306,0,0,0,.432,0l1.109-1.109a1.225,1.225,0,0,0,0-1.729ZM12.031,10.85V14.04H2.005V4.014H9.2a.385.385,0,0,0,.266-.11l1.253-1.253a.376.376,0,0,0-.266-.642H1.5A1.5,1.5,0,0,0,0,3.513V14.541a1.5,1.5,0,0,0,1.5,1.5H12.532a1.5,1.5,0,0,0,1.5-1.5V9.6a.377.377,0,0,0-.642-.266l-1.253,1.253A.385.385,0,0,0,12.031,10.85Z" transform="translate(0 -0.007)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "back": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.288" height="16.288" viewBox="0 0 16.288 16.288">
                                <path className={`${fillColor}`} id="Icon_ionic-ios-arrow-dropleft-circle" data-name="Icon ionic-ios-arrow-dropleft-circle" d="M11.519,3.375a8.144,8.144,0,1,0,8.144,8.144A8.143,8.143,0,0,0,11.519,3.375Zm1.7,11.319a.759.759,0,0,1,0,1.069.746.746,0,0,1-.532.219.76.76,0,0,1-.536-.223l-3.68-3.692a.755.755,0,0,1,.023-1.041l3.735-3.747A.756.756,0,0,1,13.3,8.348L10.09,11.519Z" transform="translate(-3.375 -3.375)" />
                            </svg>

                        </div>
                    )

                    case "info": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.609" height="15.609" viewBox="0 0 15.609 15.609">
                                <path className={`${fillColor}`} id="Icon_ionic-ios-information-circle" data-name="Icon ionic-ios-information-circle" d="M11.18,3.375a7.8,7.8,0,1,0,7.8,7.8A7.8,7.8,0,0,0,11.18,3.375Zm.713,11.407H10.459V9.375h1.433Zm-.717-6a.749.749,0,1,1,.777-.75A.752.752,0,0,1,11.176,8.786Z" transform="translate(-3.375 -3.375)" fill="#fff" />
                            </svg>

                        </div>
                    )

                    case "cross": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.385" height="22.385" viewBox="0 0 22.385 22.385" className="text-black">
                                <path className={`${fillColor}`} id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M14.567,3.375A11.192,11.192,0,1,0,25.76,14.567,11.191,11.191,0,0,0,14.567,3.375ZM17.4,18.619l-2.836-2.836-2.836,2.836A.86.86,0,1,1,10.515,17.4l2.836-2.836-2.836-2.836a.86.86,0,0,1,1.216-1.216l2.836,2.836L17.4,10.515a.86.86,0,0,1,1.216,1.216l-2.836,2.836L18.619,17.4a.864.864,0,0,1,0,1.216A.854.854,0,0,1,17.4,18.619Z" transform="translate(-3.375 -3.375)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "crossTransparent": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.057" height="19.057" viewBox="0 0 19.057 19.057">
                                <path className={`${fillColor}`} id="Icon_metro-cancel" data-name="Icon metro-cancel" d="M12.1,1.928a9.529,9.529,0,1,0,9.529,9.529A9.529,9.529,0,0,0,12.1,1.928Zm0,17.271a7.742,7.742,0,1,1,7.742-7.742A7.742,7.742,0,0,1,12.1,19.2ZM15.077,6.692,12.1,9.67,9.122,6.692,7.335,8.479l2.978,2.978L7.335,14.435l1.787,1.787L12.1,13.243l2.978,2.978,1.787-1.787-2.978-2.978,2.978-2.978Z" transform="translate(-2.571 -1.928)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "setting": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg width="17.239" height="17.243" viewBox="0 0 17.239 17.243">
                                <path id="Icon_ionic-ios-settings" className={`${fillColor}`} data-name="Icon ionic-ios-settings" d="M20.316,13.119a2.218,2.218,0,0,1,1.423-2.07,8.793,8.793,0,0,0-1.064-2.563,2.248,2.248,0,0,1-.9.193,2.213,2.213,0,0,1-2.025-3.116A8.766,8.766,0,0,0,15.189,4.5a2.216,2.216,0,0,1-4.139,0A8.793,8.793,0,0,0,8.487,5.564,2.213,2.213,0,0,1,6.462,8.68a2.175,2.175,0,0,1-.9-.193A8.987,8.987,0,0,0,4.5,11.054a2.218,2.218,0,0,1,0,4.139,8.793,8.793,0,0,0,1.064,2.563,2.214,2.214,0,0,1,2.923,2.923,8.844,8.844,0,0,0,2.563,1.064,2.212,2.212,0,0,1,4.13,0,8.793,8.793,0,0,0,2.563-1.064,2.216,2.216,0,0,1,2.923-2.923,8.844,8.844,0,0,0,1.064-2.563A2.229,2.229,0,0,1,20.316,13.119ZM13.16,16.706a3.591,3.591,0,1,1,3.591-3.591A3.59,3.59,0,0,1,13.16,16.706Z" transform="translate(-4.5 -4.5)" fill="#fff" />
                            </svg>

                        </div>
                    )

                    case "add": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.392" height="19.392" viewBox="0 0 19.392 19.392" >
                                <g id="Icon_feather-plus-circle" className={`${fillColor}`} data-name="Icon feather-plus-circle" transform="translate(-2 -2)">
                                    <path className={`${fillColor}`} id="Path_2849" data-name="Path 2849" d="M20.392,11.7A8.7,8.7,0,1,1,11.7,3a8.7,8.7,0,0,1,8.7,8.7Z" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path className={`${fillColor}`} id="Path_2850" data-name="Path 2850" d="M18,12v6.957" transform="translate(-6.304 -3.783)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path className={`${fillColor}`} id="Path_2851" data-name="Path 2851" d="M12,18h6.957" transform="translate(-3.783 -6.304)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                    )

                    case "delete": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.889" height="14" viewBox="0 0 10.889 14">
                                <path className={`${fillColor}`} id="Icon_material-delete-forever" data-name="Icon material-delete-forever" d="M8.278,16.944A1.56,1.56,0,0,0,9.833,18.5h6.222a1.56,1.56,0,0,0,1.556-1.556V7.611H8.278Zm1.913-5.538,1.1-1.1,1.657,1.649,1.649-1.649,1.1,1.1-1.649,1.649L15.69,14.7l-1.1,1.1-1.649-1.649L11.3,15.8l-1.1-1.1,1.649-1.649Zm5.476-6.129L14.889,4.5H11l-.778.778H7.5V6.833H18.389V5.278Z" transform="translate(-7.5 -4.5)" fill="#fff" />
                            </svg>

                        </div>
                    )

                    case "megaFile": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="lg:block hidden" width="44.038" height="54.048" viewBox="0 0 44.038 54.048">
                                <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(2 2)">
                                    <path className={`${fillColor}`} id="Path_2852" data-name="Path 2852" d="M31.024,3H11A5,5,0,0,0,6,8V48.043a5,5,0,0,0,5,5H41.034a5,5,0,0,0,5-5V18.014Z" transform="translate(-6 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path className={`${fillColor}`} id="Path_2853" data-name="Path 2853" d="M21,3V18.014H36.014" transform="translate(4.024 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path className={`${fillColor}`} id="Path_2854" data-name="Path 2854" d="M18,33.014V18" transform="translate(2.019 7.024)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path className={`${fillColor}`} id="Path_2855" data-name="Path 2855" d="M13.5,22.5H28.514" transform="translate(-0.988 10.031)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                </g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="block lg:hidden" width="28.771" height="35.339" viewBox="0 0 28.771 35.339">
                                <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(1.25 1.25)">
                                    <path id="Path_2852" data-name="Path 2852" d="M22.42,3H9.284A3.284,3.284,0,0,0,6,6.284V32.555a3.284,3.284,0,0,0,3.284,3.284h19.7a3.284,3.284,0,0,0,3.284-3.284v-19.7Z" transform="translate(-6 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_2853" data-name="Path 2853" d="M21,3v9.852h9.852" transform="translate(-4.58 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_2854" data-name="Path 2854" d="M18,27.852V18" transform="translate(-4.864 -1.58)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_2855" data-name="Path 2855" d="M13.5,22.5h9.852" transform="translate(-5.29 -1.154)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                </g>
                            </svg>


                        </div>
                    )

                    case "eye": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15" width="21" height="15" className="fill-gray-500"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                                <path d="m-1214-2194h1440v5469h-1440z" /></clipPath></defs>
                                <g clipPath="url(#cp1)"><path fillRule="evenodd" className="s0" d="m20.6 7.7c-1.6 4-5.5 6.8-10 6.8c-4.5 0-8.3-2.8-9.9-6.8c1.6-3.9 5.4-6.7 9.9-6.7c4.5 0 8.4 2.8 10 6.7zm-5.5 0c0-2.5-2-4.5-4.5-4.5c-2.5 0-4.5 2-4.5 4.5c0 2.5 2 4.5 4.5 4.5c2.5 0 4.5-2 4.5-4.5zm-1.8 0c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7c1.5 0 2.7 1.2 2.7 2.7z" /></g></svg>
                        </div>
                    )

                    case "heart": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" className="fill-gray-500" viewBox="0 0 19 17" width="19" height="17"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                                <path d="m-1276-2193h1440v5469h-1440z" /></clipPath></defs><g clipPath="url(#cp1)"><path className="s0" d="m17.6 9.3l-6.9 7.1c-0.5 0.4-1.2 0.4-1.6 0l-7-7.1c-2-2.1-1.8-5.6 0.4-7.5c2-1.6 4.9-1.3 6.7 0.5l0.7 0.7l0.7-0.7c1.8-1.8 4.7-2.1 6.6-0.5c2.3 1.9 2.4 5.4 0.4 7.5z" /></g></svg>         </div>
                    )

                    case "ether": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" className="fill-white" viewBox="0 0 17 28" width="17" height="28"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-877-2536h1440v5469h-1440z" /></clipPath></defs>
                                <g clipPath="url(#cp1)"><path className="s0" d="m8.5 20.8l8.2-4.8l-8.2 11.5l-8.2-11.5l8.2 4.8zm8.4-6.4l-8.3 4.9l-8.2-4.9l8.2-13.7z" /></g></svg>
                        </div>
                    )

                    case "logo": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="hidden lg:block" width="288.943" height="53" viewBox="0 0 288.943 53">
                                <g id="Group_3448" data-name="Group 3448" transform="translate(-165 -4528.186)">
                                    <g id="Group_3442" data-name="Group 3442" transform="translate(165 4533.393)">
                                        <path id="Path_2845" data-name="Path 2845" d="M-91.15,81.982A13.971,13.971,0,0,1-97.5,71.527v-2.46A13.971,13.971,0,0,1-91.15,58.612a15.984,15.984,0,0,1,13.325-1.64L-34.16,72.347a6.893,6.893,0,0,0,6.765,4.92,6.912,6.912,0,0,0,6.97-6.97,6.912,6.912,0,0,0-6.97-6.97,7.392,7.392,0,0,0-4.1,1.23l-4.715-1.64h0l-16.605-5.945h0l-22.14-7.79a23.459,23.459,0,0,0-20.5,2.46,22.126,22.126,0,0,0-10.045,16.6v3.69A22.126,22.126,0,0,0-95.455,88.542a24.032,24.032,0,0,0,12.71,3.69A23.525,23.525,0,0,0-75.16,91l7.79-2.665-12.71-4.51A13.33,13.33,0,0,1-91.15,81.982Z" transform="translate(105.5 -47.851)" fill="#fff" />
                                        <path id="Path_2846" data-name="Path 2846" d="M-12.42,68.347A22.126,22.126,0,0,0-22.465,51.742a23.379,23.379,0,0,0-20.5-2.46l-7.79,2.665,12.71,4.51a15.241,15.241,0,0,1,11.07,2.255A13.971,13.971,0,0,1-20.62,69.167v2.46a13.971,13.971,0,0,1-6.355,10.455A15.984,15.984,0,0,1-40.3,83.722l-43.665-15.58a6.893,6.893,0,0,0-6.765-4.92,6.912,6.912,0,0,0-6.97,6.97,6.912,6.912,0,0,0,6.97,6.97,7.392,7.392,0,0,0,4.1-1.23L-42.965,91.1a24.463,24.463,0,0,0,7.585,1.23,23.019,23.019,0,0,0,12.71-3.69A22.126,22.126,0,0,0-12.625,72.037c0-.615.205-1.586.205-2.2Z" transform="translate(113.69 -47.746)" fill="#fff" />
                                    </g>
                                    <text id="Lorem_ipsum_dolor_si" data-name="Lorem ipsum dolor si" transform="translate(277.943 4570.186)" fill="#fff" fontSize="43" fontFamily="Helvetica"><tspan x="0" y="0">AR</tspan><tspan y="0" fontFamily="Montserrat-Regular, Montserrat">tonar</tspan></text>
                                </g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="block lg:hidden" width="200" height="30" viewBox="0 0 288.943 53">
                                <g id="Group_3448" data-name="Group 3448" transform="translate(-165 -4528.186)">
                                    <g id="Group_3442" data-name="Group 3442" transform="translate(165 4533.393)">
                                        <path id="Path_2845" data-name="Path 2845" d="M-91.15,81.982A13.971,13.971,0,0,1-97.5,71.527v-2.46A13.971,13.971,0,0,1-91.15,58.612a15.984,15.984,0,0,1,13.325-1.64L-34.16,72.347a6.893,6.893,0,0,0,6.765,4.92,6.912,6.912,0,0,0,6.97-6.97,6.912,6.912,0,0,0-6.97-6.97,7.392,7.392,0,0,0-4.1,1.23l-4.715-1.64h0l-16.605-5.945h0l-22.14-7.79a23.459,23.459,0,0,0-20.5,2.46,22.126,22.126,0,0,0-10.045,16.6v3.69A22.126,22.126,0,0,0-95.455,88.542a24.032,24.032,0,0,0,12.71,3.69A23.525,23.525,0,0,0-75.16,91l7.79-2.665-12.71-4.51A13.33,13.33,0,0,1-91.15,81.982Z" transform="translate(105.5 -47.851)" fill="#fff" />
                                        <path id="Path_2846" data-name="Path 2846" d="M-12.42,68.347A22.126,22.126,0,0,0-22.465,51.742a23.379,23.379,0,0,0-20.5-2.46l-7.79,2.665,12.71,4.51a15.241,15.241,0,0,1,11.07,2.255A13.971,13.971,0,0,1-20.62,69.167v2.46a13.971,13.971,0,0,1-6.355,10.455A15.984,15.984,0,0,1-40.3,83.722l-43.665-15.58a6.893,6.893,0,0,0-6.765-4.92,6.912,6.912,0,0,0-6.97,6.97,6.912,6.912,0,0,0,6.97,6.97,7.392,7.392,0,0,0,4.1-1.23L-42.965,91.1a24.463,24.463,0,0,0,7.585,1.23,23.019,23.019,0,0,0,12.71-3.69A22.126,22.126,0,0,0-12.625,72.037c0-.615.205-1.586.205-2.2Z" transform="translate(113.69 -47.746)" fill="#fff" />
                                    </g>
                                    <text id="Lorem_ipsum_dolor_si" data-name="Lorem ipsum dolor si" transform="translate(277.943 4570.186)" fill="#fff" fontSize="43" fontFamily="Helvetica"><tspan x="0" y="0">AR</tspan><tspan y="0" fontFamily="Montserrat-Regular, Montserrat">tonar</tspan></text>
                                </g>
                            </svg>

                        </div>

                    )

                    case "logosmall": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer " {...props}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="210.576" height="39" viewBox="0 0 210.576 39">
                                <g id="Group_3754" data-name="Group 3754" transform="translate(-26.424 -6.392)">
                                    <g id="Group_3377" data-name="Group 3377" transform="translate(19.497 6.392)">
                                        <g id="Group_3374" data-name="Group 3374" transform="translate(6.926 5.813)">
                                            <path id="Path_2847" data-name="Path 2847" d="M43.289,69.37a8.82,8.82,0,0,1-4.012-6.6V61.216a8.821,8.821,0,0,1,4.012-6.6A10.091,10.091,0,0,1,51.7,53.58l27.567,9.707a4.428,4.428,0,1,0,4.271-5.695,4.667,4.667,0,0,0-2.589.777l-2.977-1.035h0L67.492,53.58h0L53.384,48.791a14.81,14.81,0,0,0-12.942,1.553A13.969,13.969,0,0,0,34.1,60.828v2.33a13.969,13.969,0,0,0,6.342,10.483,15.172,15.172,0,0,0,8.024,2.33,14.852,14.852,0,0,0,4.789-.777l4.918-1.683-8.024-2.847A8.671,8.671,0,0,1,43.289,69.37Z" transform="translate(-34.1 -47.951)" fill="#fff" />
                                            <path id="Path_2848" data-name="Path 2848" d="M95.741,60.828A13.969,13.969,0,0,0,89.4,50.344a14.76,14.76,0,0,0-12.942-1.553l-4.918,1.683,8.024,2.847a9.622,9.622,0,0,1,6.989,1.424,8.821,8.821,0,0,1,4.012,6.6V62.9a8.82,8.82,0,0,1-4.012,6.6,10.091,10.091,0,0,1-8.413,1.035L50.571,60.828A4.428,4.428,0,1,0,46.3,66.522a4.667,4.667,0,0,0,2.589-.777l27.567,9.577a15.445,15.445,0,0,0,4.789.777,14.533,14.533,0,0,0,8.024-2.33,13.969,13.969,0,0,0,6.342-10.483c0-.388.129-.777.129-1.165A4.338,4.338,0,0,0,95.741,60.828Z" transform="translate(-31.805 -47.951)" fill="#fff" />
                                        </g>
                                        <text id="Lorem_ipsum_dolor_si" data-name="Lorem ipsum dolor si" transform="translate(78.503 30)" fill="#fff" fontSize="31" fontFamily="Halogen-Black, Halogen" fontWeight="800" letterSpacing="-0.02em"><tspan x="0" y="0">AR</tspan><tspan y="0" fontFamily="Montserrat-Medium, Montserrat" fontWeight="500">tonar</tspan></text>
                                    </g>
                                </g>
                            </svg>

                        </div>

                    )
                    case "logomicro": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="42.203" height="20.688" viewBox="0 0 42.203 20.688">
                                <g id="Group_3367" data-name="Group 3367" transform="translate(0 0)">
                                    <path id="Path_1" data-name="Path 1" d="M881.432,216.071a9.709,9.709,0,0,1-16.568,6.864,0,0,0,0,0,0,0,6.4,6.4,0,0,0-9.065,0,9.706,9.706,0,1,1-13.725-13.726l.236-.236,3.138-3.139a1.648,1.648,0,0,1,2.33,2.331l-.809.809-2.565,2.567a6.409,6.409,0,1,0,9.064,9.064,9.707,9.707,0,0,1,13.726,0h0a6.406,6.406,0,1,0,0-9.065,9.707,9.707,0,0,1-13.726,0s0,0,0,0a9.639,9.639,0,0,1-2.422-4.048c-.053-.172-.1-.344-.14-.518l3.169-.92a6.373,6.373,0,0,0,1.726,3.156h0a6.4,6.4,0,0,0,9.065,0,9.706,9.706,0,0,1,16.569,6.863Z" transform="translate(-839.229 -205.089)" fill="#fff" />
                                    <path id="Path_2" data-name="Path 2" d="M1084.183,248.73" transform="translate(-1068.48 -245.688)" fill="#fff" />
                                    <path id="Path_3" data-name="Path 3" d="M892.742,208.163l-.809.809h-4.66l3.138-3.139a1.648,1.648,0,0,1,2.331,2.331Z" transform="translate(-884.193 -205.089)" fill="#fff" />
                                    <path id="Path_4" data-name="Path 4" d="M1020.472,203.709l.5,1.072,4.53,1.649-1.94-4.162a1.7,1.7,0,1,0-3.09,1.441Z" transform="translate(-1008.703 -201.284)" fill="#fff" />
                                </g>
                            </svg>
                        </div>

                    )

                    case "logotext": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="83" height="27" viewBox="0 0 83 27">
                                <text id="WFRAME" transform="translate(1 21)" fill="#fff" fontSize="22" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700" letterSpacing="-0.011em"><tspan x="0" y="0">ARspar</tspan></text>
                            </svg>

                        </div>

                    )

                    case "telegram": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 45" width="53" height="45" className="fill-white"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-1074-4991h1440v5469h-1440z" /></clipPath></defs><g clipPath="url(#cp1)"><path className="s0" d="m52.8 4.7l-7.9 37.2c-0.5 2.7-2.1 3.3-4.4 2.1l-12-8.9l-5.8 5.6c-0.6 0.6-1.2 1.2-2.4 1.2c-1.5 0-1.3-0.6-1.8-2.1l-4.2-13.5l-11.8-3.7c-2.6-0.7-2.6-2.5 0.5-3.8l46.4-17.9c2.1-0.9 4.2 0.5 3.4 3.8z" /></g></svg>                        </div>
                    )
                    case "discord": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg id="Icon_zocial-discordapp" data-name="Icon zocial-discordapp" xmlns="http://www.w3.org/2000/svg" width="46.257" height="52.385" viewBox="0 0 46.257 52.385">
                                <path id="Path_2843" data-name="Path 2843" d="M25.172,16a2.791,2.791,0,0,0-2.695,2.906,2.7,2.7,0,1,0,5.389,0A2.808,2.808,0,0,0,25.172,16ZM15.53,16a2.791,2.791,0,0,0-2.695,2.906,2.808,2.808,0,0,0,2.695,2.906,2.791,2.791,0,0,0,2.695-2.906A2.774,2.774,0,0,0,15.53,16Z" transform="translate(2.778 7.284)" fill="#fff" />
                                <path id="Path_2844" data-name="Path 2844" d="M42.921,0H7.521A5.423,5.423,0,0,0,2.106,5.416v35.4A5.423,5.423,0,0,0,7.521,46.23H37.479l-1.4-4.834,3.381,3.117,3.2,2.932,5.706,4.94V5.416A5.463,5.463,0,0,0,42.921,0Zm-10.2,34.21s-.951-1.136-1.744-2.113a8.376,8.376,0,0,0,4.782-3.117,15.117,15.117,0,0,1-3.038,1.559,18.944,18.944,0,0,1-3.831,1.136,18.506,18.506,0,0,1-6.842-.026,20.113,20.113,0,0,1-3.883-1.136,15.482,15.482,0,0,1-1.928-.9c-.079-.053-.159-.079-.238-.132-.053-.026-.079-.053-.106-.053-.476-.264-.74-.449-.74-.449a8.257,8.257,0,0,0,4.623,3.091c-.793,1-1.77,2.166-1.77,2.166-5.838-.185-8.057-3.989-8.057-3.989a34.879,34.879,0,0,1,3.8-15.269,13.091,13.091,0,0,1,7.4-2.747l.264.317A17.333,17.333,0,0,0,14.5,15.982s.581-.317,1.559-.74a20.7,20.7,0,0,1,6-1.664,2.6,2.6,0,0,1,.449-.053,22.352,22.352,0,0,1,5.336-.053,21.96,21.96,0,0,1,7.952,2.51,17.347,17.347,0,0,0-6.578-3.329l.37-.423a13.036,13.036,0,0,1,7.4,2.747,34.879,34.879,0,0,1,3.8,15.269c0-.026-2.219,3.778-8.057,3.963Z" transform="translate(-2.106)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "discordsmall": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg id="Icon_zocial-discordapp" data-name="Icon zocial-discordapp" xmlns="http://www.w3.org/2000/svg" width={width?width:"27.276" }height={height?height:"30.89"} viewBox="0 0 27.276 30.89">
                                <path id="Path_2843" data-name="Path 2843" d="M20.11,16A1.718,1.718,0,1,0,21.7,17.717,1.646,1.646,0,0,0,20.11,16Zm-5.686,0a1.718,1.718,0,1,0,1.589,1.714A1.646,1.646,0,0,0,14.424,16Z" transform="translate(-3.629 -2.271)" fill="#fff" />
                                <path id="Path_2844" data-name="Path 2844" d="M26.173,0H5.3A3.2,3.2,0,0,0,2.106,3.193V24.067A3.2,3.2,0,0,0,5.3,27.261H22.964l-.826-2.851,1.994,1.838,1.885,1.729,3.365,2.913V3.193A3.221,3.221,0,0,0,26.173,0ZM20.16,20.173s-.561-.67-1.028-1.246a4.939,4.939,0,0,0,2.82-1.838,8.914,8.914,0,0,1-1.791.919,11.171,11.171,0,0,1-2.259.67,10.913,10.913,0,0,1-4.035-.016,11.86,11.86,0,0,1-2.29-.67,9.129,9.129,0,0,1-1.137-.53c-.047-.031-.093-.047-.14-.078-.031-.016-.047-.031-.062-.031-.28-.156-.436-.265-.436-.265a4.869,4.869,0,0,0,2.726,1.823c-.467.592-1.044,1.277-1.044,1.277a5.657,5.657,0,0,1-4.751-2.352,20.567,20.567,0,0,1,2.243-9,7.719,7.719,0,0,1,4.362-1.62l.156.187A10.221,10.221,0,0,0,9.412,9.424s.343-.187.919-.436a12.205,12.205,0,0,1,3.536-.981,1.536,1.536,0,0,1,.265-.031,13.18,13.18,0,0,1,3.147-.031,12.949,12.949,0,0,1,4.689,1.48,10.229,10.229,0,0,0-3.879-1.963l.218-.249a7.687,7.687,0,0,1,4.362,1.62,20.567,20.567,0,0,1,2.243,9,5.709,5.709,0,0,1-4.751,2.337Z" transform="translate(-2.106)" fill="#fff" />
                            </svg>
                        </div>
                    )
                    case "instagram": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="49.132" height="51.891" viewBox="0 0 49.132 51.891">
                                <g id="instagram_3_" data-name="instagram(3)" transform="translate(2.5 2.5)">
                                    <rect id="Rectangle-path" width="44.132" height="46.89" rx="5" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5" />
                                    <path id="Shape" d="M19.3,8.281A9.7,9.7,0,1,1,11.128.105,9.7,9.7,0,0,1,19.3,8.281Z" transform="translate(12.648 13.091)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5" />
                                </g>
                            </svg>

                        </div>
                    )

                    case "calendar": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="42.536" height="47.263" viewBox="0 0 42.536 47.263">
                                <path id="Icon_material-date-range" data-name="Icon material-date-range" d="M18.679,24.268H13.953v4.726h4.726Zm9.453,0H23.405v4.726h4.726Zm9.453,0H32.858v4.726h4.726ZM42.31,7.726H39.947V3H35.221V7.726H16.316V3H11.589V7.726H9.226a4.7,4.7,0,0,0-4.7,4.726L4.5,45.536a4.725,4.725,0,0,0,4.726,4.726H42.31a4.74,4.74,0,0,0,4.726-4.726V12.453A4.74,4.74,0,0,0,42.31,7.726Zm0,37.81H9.226V19.542H42.31Z" transform="translate(-4.5 -3)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "qr": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg id="Icon_ionic-md-qr-scanner" data-name="Icon ionic-md-qr-scanner" xmlns="http://www.w3.org/2000/svg" width="54.343" height="50.163" viewBox="0 0 54.343 50.163">
                                <path id="Path_2876" data-name="Path 2876" d="M9.645,12.364a1.56,1.56,0,0,1,1.594-1.594H20.1V4.5H11.37a7.934,7.934,0,0,0-7.995,7.864v8.857h6.27Z" transform="translate(-3.375 -4.5)" fill="#fff" />
                                <path id="Path_2877" data-name="Path 2877" d="M32.456,4.5H23.625v6.27H32.4a1.626,1.626,0,0,1,1.672,1.594v8.857h6.27V12.364A7.853,7.853,0,0,0,32.456,4.5Z" transform="translate(13.997 -4.5)" fill="#fff" />
                                <path id="Path_2878" data-name="Path 2878" d="M34.076,31.226a1.56,1.56,0,0,1-1.594,1.594H23.625v6.4h8.857a7.934,7.934,0,0,0,7.864-7.995V22.5h-6.27Z" transform="translate(13.997 10.942)" fill="#fff" />
                                <path id="Path_2879" data-name="Path 2879" d="M11.239,32.82a1.56,1.56,0,0,1-1.594-1.594V22.5H3.375v8.726a8.045,8.045,0,0,0,7.995,7.995H20.1v-6.4Z" transform="translate(-3.375 10.942)" fill="#fff" />
                            </svg>



                        </div>
                    )

                    case "camera": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="66.31" height="55.163" viewBox="0 0 66.31 55.163">
                                <g id="Icon_feather-camera" data-name="Icon feather-camera" transform="translate(2.5 2.5)">
                                    <path id="Path_2880" data-name="Path 2880" d="M62.81,49.089a5.574,5.574,0,0,1-5.574,5.574H7.074A5.574,5.574,0,0,1,1.5,49.089V18.434a5.574,5.574,0,0,1,5.574-5.574H18.221L23.795,4.5H40.516l5.574,8.361H57.237a5.574,5.574,0,0,1,5.574,5.574Z" transform="translate(-1.5 -4.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" />
                                    <path id="Path_2881" data-name="Path 2881" d="M34.295,24.647A11.147,11.147,0,1,1,23.147,13.5,11.147,11.147,0,0,1,34.295,24.647Z" transform="translate(7.508 3.221)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" />
                                </g>
                            </svg>

                        </div>
                    )

                    case "share": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50.173" height="41.8" viewBox="0 0 50.173 41.8">
                                <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt" d="M54.23,24.308,35.8,7.064a.942.942,0,0,0-.732-.314c-.575.026-1.306.431-1.306,1.045v8.648a.558.558,0,0,1-.47.536C14.962,19.788,7.242,33.5,4.512,47.743c-.1.562.653,1.084,1.006.64,6.688-8.426,14.827-13.925,27.694-14.03a.646.646,0,0,1,.549.627v8.491a1.121,1.121,0,0,0,1.894.692L54.217,26.62a1.442,1.442,0,0,0,.457-1.1A1.75,1.75,0,0,0,54.23,24.308Z" transform="translate(-4.502 -6.749)" fill="#fff" />
                            </svg>
                        </div>
                    )

                    case "rewards": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="51.263" height="51.263" viewBox="0 0 51.263 51.263">
                                <g id="Icon_feather-gift" data-name="Icon feather-gift" transform="translate(2 2)">
                                    <path id="Path_2882" data-name="Path 2882" d="M43.81,18V41.631H6V18" transform="translate(-1.274 5.631)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path id="Path_2883" data-name="Path 2883" d="M3,10.5H50.263V22.316H3Z" transform="translate(-3 1.316)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path id="Path_2884" data-name="Path 2884" d="M18,45.947V10.5" transform="translate(5.631 1.316)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path id="Path_2885" data-name="Path 2885" d="M24.042,14.816H13.408A5.908,5.908,0,1,1,13.408,3C21.679,3,24.042,14.816,24.042,14.816Z" transform="translate(-0.411 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path id="Path_2886" data-name="Path 2886" d="M18,14.816H28.634A5.908,5.908,0,1,0,28.634,3C20.363,3,18,14.816,18,14.816Z" transform="translate(5.631 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                </g>
                            </svg>

                        </div>
                    )

                    case "announcement": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="47.263" height="47.263" viewBox="0 0 47.263 47.263">
                                <path id="Icon_material-announcement" data-name="Icon material-announcement" d="M45.537,3H7.726a4.72,4.72,0,0,0-4.7,4.726L3,50.263l9.453-9.453H45.537a4.74,4.74,0,0,0,4.726-4.726V7.726A4.74,4.74,0,0,0,45.537,3ZM28.995,24.268H24.268V10.089h4.726Zm0,9.453H24.268V28.994h4.726Z" transform="translate(-3 -3)" fill="#fff" />
                            </svg>

                        </div>
                    )

                    case "messagedouble": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="47.263" height="47.263" viewBox="0 0 47.263 47.263">
                                <path id="Icon_material-question-answer" data-name="Icon material-question-answer" d="M47.9,12.453H43.173V33.721H12.453v4.726a2.37,2.37,0,0,0,2.363,2.363H40.81l9.453,9.453V14.816A2.37,2.37,0,0,0,47.9,12.453ZM38.447,26.631V5.363A2.37,2.37,0,0,0,36.084,3H5.363A2.37,2.37,0,0,0,3,5.363V38.447l9.453-9.453H36.084A2.37,2.37,0,0,0,38.447,26.631Z" transform="translate(-3 -3)" fill="#fff" />
                            </svg>

                        </div>
                    )
                    case "bluetick": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg className="lg:block hidden" xmlns="http://www.w3.org/2000/svg" width="41.456" height="41.456" viewBox="0 0 41.456 41.456">
                                <g id="Icon_ionic-ios-checkmark-circle-outline" data-name="Icon ionic-ios-checkmark-circle-outline" transform="translate(-3.375 -3.375)">
                                    <path id="Path_2887" data-name="Path 2887" d="M31.78,14.13l-1.754-1.8a.377.377,0,0,0-.279-.12h0a.362.362,0,0,0-.279.12L17.31,24.573l-4.425-4.425a.385.385,0,0,0-.558,0l-1.774,1.774a.4.4,0,0,0,0,.568l5.581,5.581a1.765,1.765,0,0,0,1.166.568,1.849,1.849,0,0,0,1.156-.548h.01L31.79,14.7A.426.426,0,0,0,31.78,14.13Z" transform="translate(2.946 3.685)" fill="#278efc" />
                                    <path id="Path_2888" data-name="Path 2888" d="M24.1,6.165a17.931,17.931,0,1,1-12.686,5.252A17.819,17.819,0,0,1,24.1,6.165m0-2.79A20.728,20.728,0,1,0,44.831,24.1,20.725,20.725,0,0,0,24.1,3.375Z" transform="translate(0 0)" fill="#278efc" />
                                </g>
                            </svg>
                            <svg className="block lg:hidden" xmlns="http://www.w3.org/2000/svg" width="25.94" height="25.94" viewBox="0 0 25.94 25.94">
                                <g id="Icon_ionic-ios-checkmark-circle-outline" data-name="Icon ionic-ios-checkmark-circle-outline" transform="translate(-3.375 -3.375)">
                                    <path id="Path_2887" data-name="Path 2887" d="M23.791,13.41l-1.1-1.129a.236.236,0,0,0-.175-.075h0a.226.226,0,0,0-.175.075l-7.608,7.664-2.769-2.769a.241.241,0,0,0-.349,0l-1.11,1.11a.248.248,0,0,0,0,.355L14,22.133a1.1,1.1,0,0,0,.73.355,1.157,1.157,0,0,0,.723-.343h.006L23.8,13.765A.267.267,0,0,0,23.791,13.41Z" transform="translate(-0.799 -0.999)" fill="#278efc" />
                                    <path id="Path_2888" data-name="Path 2888" d="M16.345,5.121A11.22,11.22,0,1,1,8.407,8.407a11.15,11.15,0,0,1,7.938-3.286m0-1.746a12.97,12.97,0,1,0,12.97,12.97,12.968,12.968,0,0,0-12.97-12.97Z" transform="translate(0 0)" fill="#278efc" />
                                </g>
                            </svg>

                        </div>
                    )



                    case "bluebox": return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg className="lg:block hidden" xmlns="http://www.w3.org/2000/svg" width="44.136" height="48.537" viewBox="0 0 44.136 48.537">
                                <g id="Icon_feather-box" data-name="Icon feather-box" transform="translate(2.134 2.068)">
                                    <path id="Path_2831" data-name="Path 2831" d="M44.367,34.006V16.288a4.43,4.43,0,0,0-2.215-3.832L26.648,3.6a4.43,4.43,0,0,0-4.43,0l-15.5,8.859A4.43,4.43,0,0,0,4.5,16.288V34.006a4.43,4.43,0,0,0,2.215,3.832l15.5,8.859a4.43,4.43,0,0,0,4.43,0l15.5-8.859A4.43,4.43,0,0,0,44.367,34.006Z" transform="translate(-4.5 -3.003)" fill="none" stroke="#278efc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path id="Path_2832" data-name="Path 2832" d="M4.905,10.44,24.241,21.625,43.576,10.44" transform="translate(-4.307 0.541)" fill="none" stroke="#278efc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <path id="Path_2833" data-name="Path 2833" d="M18,40.326V18" transform="translate(1.934 4.144)" fill="none" stroke="#278efc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                </g>
                            </svg>

                            <svg className="block lg:hidden" xmlns="http://www.w3.org/2000/svg" width="27.106" height="30.045" viewBox="0 0 27.106 30.045">
                                <g id="Icon_feather-box" data-name="Icon feather-box" transform="translate(-3.5 -1.963)">
                                    <path id="Path_2831" data-name="Path 2831" d="M29.606,22.527V11.369a2.79,2.79,0,0,0-1.395-2.413L18.448,3.377a2.79,2.79,0,0,0-2.79,0L5.895,8.956A2.79,2.79,0,0,0,4.5,11.369V22.527A2.79,2.79,0,0,0,5.895,24.94l9.764,5.579a2.79,2.79,0,0,0,2.79,0l9.764-5.579A2.79,2.79,0,0,0,29.606,22.527Z" transform="translate(0 0)" fill="none" stroke="#278efc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_2832" data-name="Path 2832" d="M4.905,10.44l12.177,7.044L29.258,10.44" transform="translate(-0.028 -0.522)" fill="none" stroke="#278efc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_2833" data-name="Path 2833" d="M18,32.06V18" transform="translate(-0.947 -1.052)" fill="none" stroke="#278efc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>


                        </div>
                    )


                    case "twitter": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 45" width="55" height="45" className="fill-white"><defs><clipPath clipPathUnits="userSpaceOnUse" id="cp1"><path d="m-1183-4991h1440v5469h-1440z" /></clipPath></defs><g clipPath="url(#cp1)"><path className="s0" d="m46.1 12.9q0.1 0.6 0.1 1.2c0 13-9.9 27.9-27.9 27.9c-5.5 0-10.7-1.6-15-4.4q1.1 0.1 2.3 0.1c4.6 0 8.8-1.5 12.2-4.1c-4.3-0.1-7.9-3-9.2-6.9c0.6 0.2 1.2 0.2 1.9 0.2q1.3 0 2.5-0.3c-4.4-0.9-7.8-4.9-7.8-9.6q0-0.1 0-0.2c1.3 0.8 2.8 1.2 4.4 1.3c-2.6-1.8-4.3-4.8-4.3-8.2c0-1.8 0.5-3.5 1.3-4.9c4.8 5.9 12 9.8 20.2 10.2q-0.3-1.1-0.2-2.2c0-5.4 4.3-9.8 9.7-9.8c2.8 0 5.4 1.2 7.2 3.1c2.2-0.5 4.3-1.3 6.2-2.4c-0.7 2.3-2.3 4.2-4.3 5.4q3-0.4 5.6-1.5c-1.3 2-2.9 3.7-4.9 5.1z" /></g>
                            </svg>
                        </div>

                    )

                    case "dash": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="2" viewBox="0 0 30 2">
                                <line id="Line_359" data-name="Line 359" x2="28" transform="translate(1 1)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2" />
                            </svg>
                        </div>
                    )

                    case "doubleFile": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg id="Icon_ionic-ios-copy" data-name="Icon ionic-ios-copy" xmlns="http://www.w3.org/2000/svg" width="17.539" height="21.923" viewBox="0 0 17.539 21.923">
                                <path id="Path_2872" data-name="Path 2872" d="M24.279,5.649H28.39a.275.275,0,0,0,.274-.274h0a1.275,1.275,0,0,0-.457-.973L24.686,1.47a1.708,1.708,0,0,0-.941-.338h0a.337.337,0,0,0-.338.338V4.781A.874.874,0,0,0,24.279,5.649Z" transform="translate(-11.125 -1.127)" fill="#fff" />
                                <path id="Path_2873" data-name="Path 2873" d="M17.57,4.779V1.125H11.587a1.466,1.466,0,0,0-1.462,1.462V17.933a1.466,1.466,0,0,0,1.462,1.462H22.548a1.466,1.466,0,0,0,1.462-1.462V6.834H19.625A2.058,2.058,0,0,1,17.57,4.779Z" transform="translate(-6.471 -1.125)" fill="#fff" />
                                <path id="Path_2874" data-name="Path 2874" d="M6.875,20.789V5.625H5.962A1.466,1.466,0,0,0,4.5,7.087V23.164a1.466,1.466,0,0,0,1.462,1.462H17.654a1.466,1.466,0,0,0,1.462-1.462V22.25H8.337A1.466,1.466,0,0,1,6.875,20.789Z" transform="translate(-4.5 -2.702)" fill="#fff" />
                            </svg>

                        </div>
                    )
                    case "download": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
                                <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(-3.75 -3.75)">
                                    <path id="Path_2869" data-name="Path 2869" d="M18.5,22.5v3.111a1.556,1.556,0,0,1-1.556,1.556H6.056A1.556,1.556,0,0,1,4.5,25.611V22.5" transform="translate(0 -8.667)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Path_2870" data-name="Path 2870" d="M10.5,15l3.889,3.889L18.278,15" transform="translate(-2.889 -5.056)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Path_2871" data-name="Path 2871" d="M18,13.833V4.5" transform="translate(-6.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </g>
                            </svg>


                        </div>
                    )

                    case "menubar": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="52" viewBox="0 0 61 52">
                                <defs>
                                    <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 86 74">
                                    </pattern>
                                </defs>
                                <rect id="Screenshot_2022-02-23_at_10.23.53" data-name="Screenshot 2022-02-23 at 10.23.53" width="61" height="52" fill="url(#pattern)" />
                            </svg>

                        </div>
                    )

                    case "videoplay": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.633" height="25.633" viewBox="0 0 25.633 25.633">
                                <path id="Icon_awesome-play-circle" data-name="Icon awesome-play-circle" d="M13.379.563A12.817,12.817,0,1,0,26.2,13.379,12.814,12.814,0,0,0,13.379.563Zm5.979,14.057-9.1,5.22a1.243,1.243,0,0,1-1.845-1.085V8a1.243,1.243,0,0,1,1.845-1.085l9.1,5.53A1.244,1.244,0,0,1,19.358,14.619Z" transform="translate(-0.563 -0.563)" />
                            </svg>


                        </div>
                    )

                    case "bid": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.73" height="17.289" viewBox="0 0 18.73 17.289">
                                <g id="Icon_ionic-md-wallet" data-name="Icon ionic-md-wallet" transform="translate(0 0)">
                                    <path id="Path_2826" data-name="Path 2826" d="M10.34,16.985V9.3a1.922,1.922,0,0,1,1.923-1.923h8.87V6.423a1.915,1.915,0,0,0-1.9-1.923H5.356A1.946,1.946,0,0,0,3.375,6.391V19.9a1.946,1.946,0,0,0,1.981,1.891H19.223a1.912,1.912,0,0,0,1.9-1.923v-.959h-8.87A1.921,1.921,0,0,1,10.34,16.985Z" transform="translate(-3.375 -4.5)" fill="#fff" />
                                    <path id="Path_2827" data-name="Path 2827" d="M17.227,12.674v6.3a.722.722,0,0,0,.72.72h8.419a.722.722,0,0,0,.72-.72v-6.3a.722.722,0,0,0-.72-.72H17.947A.723.723,0,0,0,17.227,12.674ZM20.7,17.261a1.441,1.441,0,1,1,1.346-1.346A1.442,1.442,0,0,1,20.7,17.261Z" transform="translate(-8.357 -7.181)" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                    )

                    case "arsparlogo": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg id="Group_3821" data-name="Group 3821" xmlns="http://www.w3.org/2000/svg" width="124.152" height="60.858" viewBox="0 0 124.152 60.858">
                                <g id="Group_3688" data-name="Group 3688" transform="translate(0)">
                                    <path id="Path_1" data-name="Path 1" d="M963.381,236.889a28.563,28.563,0,0,1-48.739,20.193,0,0,0,0,0,0,0,18.831,18.831,0,0,0-26.668,0,28.552,28.552,0,1,1-40.376-40.38l.694-.695,9.232-9.235a4.848,4.848,0,1,1,6.856,6.856L862,216l-7.547,7.551a18.854,18.854,0,1,0,26.664,26.664,28.555,28.555,0,0,1,40.38,0v0a18.846,18.846,0,1,0,0-26.668,28.556,28.556,0,0,1-40.38,0s0,0-.007-.007a28.35,28.35,0,0,1-7.124-11.907c-.155-.505-.293-1.011-.413-1.523l9.321-2.706a18.759,18.759,0,0,0,5.078,9.284v0a18.83,18.83,0,0,0,26.668,0,28.554,28.554,0,0,1,48.742,20.19Z" transform="translate(-839.229 -204.583)" fill="#fff" />
                                    <path id="Path_2" data-name="Path 2" d="M1084.183,248.73" transform="translate(-1037.989 -239.782)" fill="#fff" />
                                    <path id="Path_3" data-name="Path 3" d="M903.361,213.626,900.982,216H887.273l9.232-9.235a4.848,4.848,0,1,1,6.856,6.856Z" transform="translate(-878.213 -204.583)" fill="#fff" />
                                    <path id="Path_4" data-name="Path 4" d="M1020.784,208.42l1.471,3.155,13.326,4.85-5.706-12.243a5.015,5.015,0,0,0-9.09,4.239Z" transform="translate(-986.163 -201.284)" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                    )

                    case "arsparroundlogo": return (
                        <div className="cursor-pointer flex flex-col justify-center " {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="73" viewBox="0 0 74 73">
                                <defs>
                                    <filter id="Ellipse_43" x="0" y="0" width="74" height="73" filterUnits="userSpaceOnUse">
                                        <feOffset input="SourceAlpha" />
                                        <feGaussianBlur stdDeviation="5" result="blur" />
                                        <feFlood floodOpacity="0.161" />
                                        <feComposite operator="in" in2="blur" />
                                        <feComposite in="SourceGraphic" />
                                    </filter>
                                </defs>
                                <g id="Group_3722" data-name="Group 3722" transform="translate(14.863 14.63)">
                                    <g transform="matrix(1, 0, 0, 1, -14.86, -14.63)" filter="url(#Ellipse_43)">
                                        <g id="Ellipse_43-2" data-name="Ellipse 43" transform="translate(15 15)" fill="#fff" stroke="#c4c4c4" strokeWidth="1">
                                            <ellipse cx="22" cy="21.5" rx="22" ry="21.5" stroke="none" />
                                            <ellipse cx="22" cy="21.5" rx="21.5" ry="21" fill="none" />
                                        </g>
                                    </g>
                                    <g id="Group_3240" data-name="Group 3240" transform="translate(6.465 14.644)">
                                        <path id="Path_1" data-name="Path 1" d="M870.124,213.2A7.108,7.108,0,0,1,858,218.224h0a4.686,4.686,0,0,0-6.636,0,7.105,7.105,0,1,1-10.048-10.049l.173-.173,2.3-2.3a1.206,1.206,0,1,1,1.706,1.706L844.9,208l-1.878,1.879a4.692,4.692,0,1,0,6.635,6.635,7.106,7.106,0,0,1,10.048,0h0a4.69,4.69,0,1,0,0-6.636,7.106,7.106,0,0,1-10.048,0l0,0a7.054,7.054,0,0,1-1.773-2.963c-.039-.126-.073-.252-.1-.379l2.319-.673a4.668,4.668,0,0,0,1.264,2.31h0a4.686,4.686,0,0,0,6.636,0,7.106,7.106,0,0,1,12.129,5.024Z" transform="translate(-839.229 -205.159)" />
                                        <path id="Path_2" data-name="Path 2" d="M1084.183,248.73" transform="translate(-1072.688 -246.503)" />
                                        <path id="Path_3" data-name="Path 3" d="M891.276,207.409l-.592.592h-3.411l2.3-2.3a1.206,1.206,0,0,1,1.706,1.706Z" transform="translate(-885.018 -205.159)" />
                                        <path id="Path_4" data-name="Path 4" d="M1020.429,203.059l.366.785,3.316,1.207-1.42-3.047a1.248,1.248,0,0,0-2.262,1.055Z" transform="translate(-1011.814 -201.284)" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    )


                    default: return (
                        <div className="mr-2 flex flex-col justify-center cursor-pointer" {...props}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.366" height="15.155" viewBox="0 0 11.366 15.155">
                                <path className={`${fillColor}`} id="Icon_awesome-file-download" data-name="Icon awesome-file-download" d="M6.63,4.026V0H.71A.709.709,0,0,0,0,.71V14.444a.709.709,0,0,0,.71.71h9.945a.709.709,0,0,0,.71-.71V4.736H7.341A.712.712,0,0,1,6.63,4.026Zm2.263,6.256L6.039,13.114a.5.5,0,0,1-.712,0L2.474,10.282a.473.473,0,0,1,.333-.81H4.736V7.1a.473.473,0,0,1,.474-.474h.947A.473.473,0,0,1,6.63,7.1V9.472H8.56A.474.474,0,0,1,8.893,10.282Zm2.266-7.174-2.9-2.9A.71.71,0,0,0,7.758,0H7.577V3.789h3.789V3.608A.708.708,0,0,0,11.159,3.108Z" fill="#fff" />
                            </svg>

                        </div>
                    )
                }
            })()}
        </>
    )


};

export default Icons;

