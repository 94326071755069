import React from 'react';
import '../../../src/assets/styles/output.css';
import Icons from '../../assets/images/svg/Icons.js';
import { ButtonSize, ButtonType, ButtonWeight } from '../../../src/utils/helper.js';

function Button({ size, children, type, icon, iconHeight, iconWidth,  weight, isDisable,iconColor, ...props }) {

    return (
        <>
            {isDisable ?
                <div className={`${ButtonSize[size ? size : 'large']} ${ButtonType['disabled']} ${ButtonWeight[weight ? weight : 'medium']}`}>
                    {icon ? <Icons icon={icon} bgColor={iconColor?iconColor:''} height={iconHeight?iconHeight:null} width={iconWidth?iconWidth:null}/> : null}
                    <span className='my-auto'>{children}</span>
                </div>
                :
                <div {...props} className={`${ButtonSize[size ? size : 'large']} ${ButtonType[type ? type : 'primary']} ${ButtonWeight[weight ? weight : 'medium']}`}>
                    {icon ? <Icons icon={icon} bgColor={iconColor?iconColor:''}  height={iconHeight?iconHeight:null} width={iconWidth?iconWidth:null}/> : null}
                    <span className='my-auto'>{children}</span>
                </div>
            }
        </>

    )
};

export default Button;