import {
  AmbientLight,
  AnimationMixer,
  AxesHelper,
  Box3,
  BoxHelper,
  Cache,
  DirectionalLight,
  GridHelper,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  REVISION,
  Scene,
  SkeletonHelper,
  Vector3,
  WebGLRenderer,
  sRGBEncoding,
  Sphere,
  PlaneBufferGeometry,
  ShadowMaterial,
  Mesh
} from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import { environments } from '../../assets/environment';
// import { createBackground } from '../../utils/threejs/lib-background/three-vignette';

const DEFAULT_CAMERA = '[default]';

const MANAGER = new LoadingManager();
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`
const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(`${THREE_PATH}/examples/js/libs/draco/gltf/`);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(`${THREE_PATH}/examples/js/libs/basis/`);

const IS_IOS = isIOS();

// glTF texture types. `envMap` is deliberately omitted, as it's used internally
// by the loader but not part of the glTF format.
const MAP_NAMES = [
  'map',
  'aoMap',
  'emissiveMap',
  'glossinessMap',
  'metalnessMap',
  'normalMap',
  'roughnessMap',
  'specularMap',
];

const Preset = { ASSET_GENERATOR: 'assetgenerator' };

Cache.enabled = true;

export class Viewer {

  constructor(el, options) {
    this.el = el;
    this.options = options;

    this.lights = [];
    this.content = null;
    this.mixer = null;
    this.clips = [];
    this.gui = null;


    this.hemisphereLight = null;
    this.shadowLight = null;
    this.light2 = null;

    this.state = {
      environment: 'Venice Sunset',
      background: false,
      playbackSpeed: 1.0,
      actionStates: {},
      camera: DEFAULT_CAMERA,
      wireframe: false,
      skeleton: false,
      grid: false,

      // Lights
      addLights: true,
      exposure: 1.0,
      textureEncoding: 'sRGB',
      ambientIntensity: 1.0,
      ambientColor: 0xFFFFFF,
      directIntensity: 2 * Math.PI, // TODO(#116)
      directColor: 0xFFFFFF,
      bgColor1: '#4c61bc',
      bgColor2: '#643fd1'
    };

    this.prevTime = 0;

    this.stats = new Stats();
    this.stats.dom.height = '48px';
    [].forEach.call(this.stats.dom.children, (child) => (child.style.display = ''));

    this.scene = new Scene();

    const fov = options.preset === Preset.ASSET_GENERATOR ? 0.8 * 180 / Math.PI : 60;
    this.defaultCamera = new PerspectiveCamera(fov, el.clientWidth / el.clientHeight, 300, 1000);
    this.activeCamera = this.defaultCamera;
    this.scene.add(this.defaultCamera);

    this.renderer = window.renderer = new WebGLRenderer({
      alpha: true,
      // antialias: true,
      // alpha: true
    });
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;
    // this.renderer.setClearColor(0xffffff);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(el.clientWidth, el.clientHeight);



    // this.renderer.setClearColor(0xebebeb, 0);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMapSoft = true;


    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.controls = new OrbitControls(this.defaultCamera, this.renderer.domElement);
    this.controls.autoRotate = false;
    this.controls.autoRotateSpeed = -10;
    this.controls.screenSpacePanning = true;
    this.controls.minZoom = 15;
    this.controls.maxZoom = 35;
    this.controls.minDistance = 15;
    this.controls.maxDistance = 35;

    // this.vignette = createBackground({
    //   aspect: this.defaultCamera.aspect,
    //   grainScale: IS_IOS ? 0 : 0.001, // mattdesl/three-vignette-background#1
    //   colors: [this.state.bgColor1, this.state.bgColor2]
    // });
    // this.vignette.name = 'Vignette';
    // this.vignette.renderOrder = -1;

    // console.log("Creating background : ", this.vignette);

    this.el.appendChild(this.renderer.domElement);

    this.cameraCtrl = null;
    this.cameraFolder = null;
    this.animFolder = null;
    this.animCtrls = [];
    this.morphFolder = null;
    this.morphCtrls = [];
    this.skeletonHelpers = [];
    this.gridHelper = null;
    this.axesHelper = null;

    // this.addAxesHelper();
    if (options.kiosk) this.gui.close();
    this.animate = this.animate.bind(this);
    requestAnimationFrame(this.animate);
    window.addEventListener('resize', this.resize.bind(this), false);
  }

  animate(time) {

    requestAnimationFrame(this.animate);

    const dt = (time - this.prevTime) / 1000;

    this.controls.update();
    this.stats.update();
    this.mixer && this.mixer.update(dt);
    this.render();

    this.prevTime = time;

  }

  render() {
    this.renderer.render(this.scene, this.activeCamera);
  }

  resize() {

    const { clientHeight, clientWidth } = this.el.parentElement;

    this.defaultCamera.aspect = clientWidth / clientHeight;
    this.defaultCamera.updateProjectionMatrix();
    // this.vignette.style({ aspect: this.defaultCamera.aspect });
    this.renderer.setSize(clientWidth, clientHeight);

  }

  load(url, rootPath, assetMap) {

    const baseURL = LoaderUtils.extractUrlBase(url);

    // Load.
    return new Promise((resolve, reject) => {


      const loader = new GLTFLoader(MANAGER).setCrossOrigin('anonymous').setDRACOLoader(DRACO_LOADER)
        .setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer)).setMeshoptDecoder(MeshoptDecoder);

      const blobURLs = [];

      loader.load(url, (gltf) => {

        const scene = gltf.scene || gltf.scenes[0];
        const clips = gltf.animations || [];

        if (!scene) {
          // Valid, but not supported by this viewer.
          throw new Error(
            'This model contains no scene, and cannot be viewed here. However,'
            + ' it may contain individual 3D resources.'
          );
        }

        this.setContent(scene, clips);

        blobURLs.forEach(URL.revokeObjectURL);
        resolve(gltf);

      }, undefined, reject);

    });

  }

  /**
   * @param {THREE.Object3D} object
   * @param {Array<THREE.AnimationClip} clips
   */
  setContent(object, clips) {

    this.clear();
    // const boxHelper = new BoxHelper(object, 0xffff00);
    // this.scene.add(boxHelper);

    const box = new Box3().setFromObject(object);
    const size = box.getSize(new Vector3()).length();
    const center = box.getCenter(new Vector3());

    this.controls.reset();

    object.position.x += (object.position.x - center.x);
    object.position.y += (object.position.y - center.y);
    object.position.z += (object.position.z - center.z);


    object.position.x = 0;
    object.position.y = -0.1;
    object.position.z = 0;

    this.controls.maxDistance = size * 20;
    this.defaultCamera.near = size / 100;
    this.defaultCamera.far = size * 100;
    this.defaultCamera.updateProjectionMatrix();

    if (this.options.cameraPosition) {
      this.defaultCamera.position.fromArray(this.options.cameraPosition);
      this.defaultCamera.lookAt(new Vector3());
    } else {
      this.defaultCamera.position.copy(center);
      this.defaultCamera.position.x += size / 1.0;
      this.defaultCamera.position.y += size / 2.0;
      this.defaultCamera.position.z += size / 2.0;
      this.defaultCamera.lookAt(center);
    }

    this.setCamera(DEFAULT_CAMERA);
    this.controls.saveState();

    this.scene.add(object);
    this.content = object;

    // object.scale.set(0.5, 0.5, 0.5);

    this.state.addLights = true;

    this.content.traverse((node) => {
      if (node.isLight) {
        this.state.addLights = false;
      } else if (node.isMesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
        node.material.depthWrite = !node.material.transparent;
      }
    });

    this.setClips(clips);

    this.updateLights();
    this.updateTextureEncoding();
    this.updateDisplay();
    this.updateEnvironment();
    this.updateBackground();
    this.createLights();
    this.createPlane();

    window.content = this.content;
    // this.printGraph(this.content);
    // this.generateVariation();
  }

  printGraph(node) {
    console.group(' <' + node.type + '> ' + node.name);
    node.children.forEach((child) => this.printGraph(child));
    console.groupEnd();
  }

  /**
   * @param {Array<THREE.AnimationClip} clips
   */
  setClips(clips) {
    if (this.mixer) {
      this.mixer.stopAllAction();
      this.mixer.uncacheRoot(this.mixer.getRoot());
      this.mixer = null;
    }

    this.clips = clips;
    if (!clips.length) return;

    this.mixer = new AnimationMixer(this.content);
    this.mixer.timeScale = 1;

    this.playAllClips()

  }

  playAllClips() {
    this.clips.forEach((clip) => {
      this.mixer.clipAction(clip).reset().play();
      this.state.actionStates[clip.name] = true;
    });
  }

  /**
   * @param {string} name
   */
  setCamera(name) {
    if (name === DEFAULT_CAMERA) {
      this.controls.enabled = true;
      this.activeCamera = this.defaultCamera;
    } else {
      this.controls.enabled = false;
      this.content.traverse((node) => {
        if (node.isCamera && node.name === name) {
          this.activeCamera = node;
        }
      });
    }
  }

  updateTextureEncoding() {
    const encoding = this.state.textureEncoding === 'sRGB' ? sRGBEncoding : LinearEncoding;
    traverseMaterials(this.content, (material) => {
      if (material.map) material.map.encoding = encoding;
      if (material.emissiveMap) material.emissiveMap.encoding = encoding;
      if (material.map || material.emissiveMap) material.needsUpdate = true;
    });
  }

  updateLights() {
    const state = this.state;
    const lights = this.lights;

    if (state.addLights && !lights.length) {
      this.addLights();
    } else if (!state.addLights && lights.length) {
      this.removeLights();
    }

    this.renderer.toneMappingExposure = state.exposure;

    if (lights.length === 2) {
      lights[0].intensity = state.ambientIntensity;
      lights[0].color.setHex(state.ambientColor);
      lights[1].intensity = state.directIntensity;
      lights[1].color.setHex(state.directColor);
    }
  }

  addLights() {
    const state = this.state;

    if (this.options.preset === Preset.ASSET_GENERATOR) {
      const hemiLight = new HemisphereLight();
      hemiLight.name = 'hemi_light';
      this.scene.add(hemiLight);
      this.lights.push(hemiLight);
      return;
    }

    const light1 = new AmbientLight(state.ambientColor, state.ambientIntensity);
    light1.name = 'ambient_light';
    this.defaultCamera.add(light1);

    const light2 = new DirectionalLight(state.directColor, state.directIntensity);
    light2.position.set(0.5, 0, 0.866); // ~60º
    light2.name = 'main_light';
    this.defaultCamera.add(light2);

    this.lights.push(light1, light2);
  }

  removeLights() {

    this.lights.forEach((light) => light.parent.remove(light));
    this.lights.length = 0;

  }

  updateEnvironment() {
    const environment = environments.filter((entry) => entry.name === this.state.environment)[0];
    this.getCubeMapTexture(environment).then(({ envMap }) => {
      if ((!envMap || !this.state.background) && this.activeCamera === this.defaultCamera) {
        this.scene.add(this.vignette);
      } else {
        this.scene.remove(this.vignette);
      }

      this.scene.environment = envMap;
      this.scene.background = this.state.background ? envMap : null;
    });
  }

  updateBackground() {
    // this.vignette.style({ colors: [this.state.bgColor1, this.state.bgColor2] });
  }

  getCubeMapTexture(environment) {
    const { path } = environment;
    // no envmap
    if (!path) return Promise.resolve({ envMap: null });
    return new Promise((resolve, reject) => {
      new RGBELoader().load(path, (texture) => {
        const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
        this.pmremGenerator.dispose();
        resolve({ envMap });
      }, undefined, reject);
    });

  }

  updateDisplay() {
    if (this.skeletonHelpers.length) {
      this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
    }

    traverseMaterials(this.content, (material) => {
      material.wireframe = this.state.wireframe;
    });

    this.content.traverse((node) => {
      if (node.isMesh && node.skeleton && this.state.skeleton) {
        const helper = new SkeletonHelper(node.skeleton.bones[0].parent);
        helper.material.linewidth = 3;
        this.scene.add(helper);
        this.skeletonHelpers.push(helper);
      }
    });

    if (this.state.grid !== Boolean(this.gridHelper)) {
      if (this.state.grid) {
        this.gridHelper = new GridHelper();
        this.axesHelper = new AxesHelper();
        this.axesHelper.renderOrder = 999;
        this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
        this.scene.add(this.gridHelper);
        this.scene.add(this.axesHelper);
      } else {
        this.scene.remove(this.gridHelper);
        this.scene.remove(this.axesHelper);
        this.gridHelper = null;
        this.axesHelper = null;
        this.axesRenderer.clear();
      }
    }
  }


  createLights() {
    this.hemisphereLight = new HemisphereLight(0xffffff, 0x000000, .5)
    this.shadowLight = new DirectionalLight(0xff8f16, .4);
    this.shadowLight.position.set(0, 450, 350);
    this.shadowLight.castShadow = true;
    this.shadowLight.shadow.camera.left = -650;
    this.shadowLight.shadow.camera.right = 650;
    this.shadowLight.shadow.camera.top = 650;
    this.shadowLight.shadow.camera.bottom = -650;
    this.shadowLight.shadow.camera.near = 1;
    this.shadowLight.shadow.camera.far = 1000;
    this.shadowLight.shadow.mapSize.width = 4096;
    this.shadowLight.shadow.mapSize.height = 4096;
    this.light2 = new DirectionalLight(0xfff150, .25);
    this.light2.position.set(-600, 350, 350);
    this.light3 = new DirectionalLight(0xfff150, .15);
    this.light3.position.set(0, -250, 300);
    this.scene.add(this.hemisphereLight);
    this.scene.add(this.shadowLight);
    this.scene.add(this.light2);
    this.scene.add(this.light3);
  }

  createPlane() {
    const planeGeometry = new PlaneBufferGeometry(2000, 2000);
    const planeMaterial = new ShadowMaterial({
      opacity: 0.15
    });
    const plane = new Mesh(planeGeometry, planeMaterial);
    plane.position.y = -150;
    plane.position.x = 0;
    plane.position.z = 0;
    plane.rotation.x = Math.PI / 180 * -90;
    plane.receiveShadow = true;
    this.scene.add(plane);
  }

  clear() {
    if (!this.content) return;
    this.scene.remove(this.content);

    // dispose geometry
    this.content.traverse((node) => {
      if (!node.isMesh) return;
      node.geometry.dispose();
    });

    // dispose textures
    traverseMaterials(this.content, (material) => {
      MAP_NAMES.forEach((map) => {
        if (material[map]) material[map].dispose();
      });
    });

  }

  getNodesInfo() {
    let parentNodes = [];
    let _object = this.content;
    if (_object.children && _object.children.length != 0) {
      let parentObjectArray = _object.children;

      // checking all objects one by one
      for (let parentSingleObj of parentObjectArray) {
        // checking of the model type is of object 3d
        if (parentSingleObj.isObject3D) {
          let childRelationArray = [];
          let subChildObj = parentSingleObj.children;
          // checkiing if the children is skinned mesh
          for (let _subChhildObj of subChildObj) {
            if (_subChhildObj.isSkinnedMesh) {
              let subChildObjInfo = {
                id: _subChhildObj.id,
                name: _subChhildObj.name,
                uuid: _subChhildObj.uuid,
                visible: _subChhildObj.visible,
                userData: _subChhildObj.userData
              }
              childRelationArray.push(subChildObjInfo)
            }
          }

          let childObjInfo = {
            id: parentSingleObj.id,
            name: parentSingleObj.name,
            uuid: parentSingleObj.uuid,
            visible: parentSingleObj.visible,
            userData: parentSingleObj.userData,
            children: childRelationArray
          }
          parentNodes.push(childObjInfo);
        }

      }
    }
    return parentNodes;
  }



  setVisiblityFalse(nodes_info) {
    console.log("Setting visible false")
    // iterating thhought the object and setting visiblity false for all models
    for (let parentNodes of nodes_info) {
      let childParent = parentNodes.children;
      for (let childObj of childParent) {
        this.scene.getObjectById(childObj.id).visible = false
      }
    }
    console.log("All child set to visible false")
    return true;
  }


  setVisiblityTrue_MultiNode(nodes_info) {
    console.log("Setting visible false")
    // iterating thhought the object and setting visiblity false for all models
    for (let parentNodes of nodes_info) {
      try {
        let modelPart = this.scene.getObjectByName(parentNodes.child_ref);
        // console.log("Model Search Result for  : ", parentNodes, modelPart);
        if (modelPart) {
          modelPart.visible = true;
        }
      } catch (error) {
        console.log("Error occured while setting model nodes")
      }
    }
    console.log("All child set to visible false")
    return true;
  }

  setVisiblityFalse_SingleNode(nodes_info) {
    try {
      let modelPart = this.scene.getObjectByName(nodes_info.child_ref);
      if (modelPart) {
        modelPart.visible = false;
      }
    } catch (error) {
      console.log("Error occured while setting model nodes")
    }
  }


  setVisiblityTure_SingleNode(nodes_info) {
    try {
      let modelPart = this.scene.getObjectByName(nodes_info.child_ref);
      if (modelPart) {
        modelPart.visible = true;
      }
    } catch (error) {
      console.log("Error occured while setting model nodes")
    }
  }


  generateVariation(nodes_info) {
    console.log("Generating Variation")
    // iterating thhought the object and setting visiblity false for all models
    for (let parentNodes of nodes_info) {
      let childParent = parentNodes.children;
      for (let childObj of childParent) {
        this.scene.getObjectById(childObj.id).visible = false
      }
    }
    console.log("All child set to visible false")
    return true;

  }
};

function traverseMaterials(object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material) ? node.material : [node.material];
    materials.forEach(callback);
  });
}

// https://stackoverflow.com/a/9039885/1314762
function isIOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}
