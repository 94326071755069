import React, { createContext, useState, useReducer } from "react";
import reducerUserAnswerResponse from "./userAnswerReducer";

const RootContext = createContext();

export const RootProvider = (props) => {



    // Image Process array
    const [imageProcessArray, setImageProcessArray] = useState([]);
    const [addMoreImage, setAddMoreImage] = useState(false);
    const [currentActivePage, setCurrentActivePage] = useState(1);
    const [nodesSelectedForVariation, setNodesSelectedForVariation] = useState([]);
    const [apiResponseGeneration, setapiResponseGeneration] = useState({});



    const context = {
        imageProcessArray, setImageProcessArray,
        addMoreImage, setAddMoreImage,
        currentActivePage, setCurrentActivePage,
        nodesSelectedForVariation, setNodesSelectedForVariation,
        apiResponseGeneration, setapiResponseGeneration
    };

    return (
        <RootContext.Provider value={context}>
            {props.children}
        </RootContext.Provider>
    );
};

export default RootContext;
